// @ts-nocheck
import { action } from "typesafe-actions";
import {
  AdminTypes,
  PersistUserBody,
  GetUsersBody,
  AdminResponse,
  User,
  RejectReason,
  UserApprovalBody,
} from "./types";

// Admin

export const getUsers = (params: GetUsersBody) =>
  action(AdminTypes.GET_USERS, { ...params });

export const setUsers = (data: AdminResponse) =>
  action(AdminTypes.SET_USERS, { data });

export const getRejectReasons = () => action(AdminTypes.GET_REJECT_REASONS);

export const setRejectReasons = (rejectReasons: RejectReason[]) =>
  action(AdminTypes.SET_REJECT_REASONS, { rejectReasons });

export const getUsersFailure = () => action(AdminTypes.GET_USERS_FAILURE);

export const setUserApproval = (userApprovalBody: UserApprovalBody) =>
  action(AdminTypes.SET_USER_APPROVAL, { ...userApprovalBody });

export const persistUser = (persistUserBody: PersistUserBody) =>
  action(AdminTypes.PERSIST_USER, { ...persistUserBody });

export const deleteUser = (userId: number) =>
  action(AdminTypes.DELETE_USER, {
    userId,
  });

export const setPage = (page: number) => action(AdminTypes.SET_PAGE, { page });

export const setSize = (size: number) => action(AdminTypes.SET_SIZE, { size });

export const getCurrentManipulatedUser = (id: number) =>
  action(AdminTypes.GET_CURRENT_MANIPULATED_USER, { id });

export const setCurrentManipulatedUser = (currentManipulatedUser: User) =>
  action(AdminTypes.SET_CURRENT_MANIPULATED_USER, { currentManipulatedUser });

export const currentManipulatedUserClean = () =>
  action(AdminTypes.CURRENT_MANIPULATED_USER_CLEAN);

export const adminClean = () => action(AdminTypes.ADMIN_CLEAN);

export const getAdminAndAdvisors = () => action(AdminTypes.GET_ADMIN_AND_ADVISORS);

export const setAdminAndAdvisors = (data: AdminResponse) =>
  action(AdminTypes.SET_ADMIN_AND_ADVISORS, { data });
