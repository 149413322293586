import { action } from "typesafe-actions";
import { NotificationsTypes, Notification } from "./types";

// Notifications

export const getNotifications = () =>
  action(NotificationsTypes.GET_NOTIFICATIONS);

export const getNotificationsFailure = () =>
  action(NotificationsTypes.GET_NOTIFICATIONS_FAILURE);

export const setNotifications = (data: Notification) =>
  action(NotificationsTypes.SET_NOTIFICATIONS, { data });

export const notificationsClean = () =>
  action(NotificationsTypes.NOTIFICATIONS_CLEAN);
