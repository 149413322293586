export default {
  fields: [
    "name",
    "occupation",
    "socialReason",
    "cnpj",
    "email",
    "company",
    "legalRepresentative",
    "legalRepresentativeName",  
    "phoneNumber",
  ],
  arraysAndFiles: [
    "socialContract",
    "attorneyLetter",
    "roles",
  ],
  messages: {
    reject: {
      error: "Falha ao desabilitar usuário!",
      success: "Usuário desabilitado com sucesso!",
    },
    approve: {
      error: "Falha ao habilitar usuário!",
      success: "Usuário habilitado com sucesso!",
    },
    post: {
      200: "Cadastro realizado com sucesso!",
      400: "Verifique os dados e tente novamente!",
      422: "E-mail já cadastrado!",
      500: "Erro ao realizar operação, verifique os dados e tente novamente!",
    },
    put: {
      200: "Atualização realizado com sucesso!",
      400: "Verifique os dados e tente novamente!",
      422: "E-mail já cadastrado!",
      500: "Erro ao realizar operação, verifique os dados e tente novamente!",
    },
  },
};
