import { Reducer } from "redux";
import { PrivacyNotesState, PrivacyNotesTypes } from "./types";

const INITIAL_STATE: PrivacyNotesState = {
  data: {
    id: 1,
    hasAccepted: false,
    term: ""
  }
};

const reducer: Reducer<PrivacyNotesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PrivacyNotesTypes.SET_PRIVACY_NOTES:
      return {
        ...state,
        data: action.payload.data,
      };
    case PrivacyNotesTypes.SET_ACCEPTED:
      return {
        ...state,
        data: {
          ...state.data,
          hasAccepted: action.payload.accepted
        }
      }
    default:
      return state;
  }
};

export default reducer;
