import { action } from "typesafe-actions";
import { StatusTypes, Status, ModifyBody } from "./types";

// Status

export const getStatus = () => action(StatusTypes.GET_STATUS);
export const setPurchaseStatus = (purchase: Status[]) =>
  action(StatusTypes.SET_PURCHASE_STATUS, { purchase });
export const setSaleStatus = (sale: Status[]) =>
  action(StatusTypes.SET_SALE_STATUS, { sale });

export const getModifyStatus = (body: ModifyBody) =>
  action(StatusTypes.GET_MODIFY_STATUS, { ...body });
export const setModifyStatus = () => action(StatusTypes.SET_MODIFY_STATUS);
export const modifyStatusClean = () => action(StatusTypes.MODIFY_STATUS_CLEAN);

export const getModifyOrderStatus = (body: ModifyBody) =>
  action(StatusTypes.GET_MODIFY_ORDER_STATUS, { ...body });

export const setStatusFiles = (files: any) =>
  action(StatusTypes.SET_STATUS_FILES, { files });
export const statusFilesAndReasonClean = () =>
  action(StatusTypes.STATUS_FILES_AND_REASON_CLEAN);
export const setStatusReason = (reason: string) =>
  action(StatusTypes.SET_STATUS_REASON, { reason });
export const setStatusDiscountPercentage = (discountPercentage?: string) =>
  action(StatusTypes.SET_STATUS_DISCOUNT_PERCENTAGE, { discountPercentage })

export const statusClean = () => action(StatusTypes.STATUS_CLEAN);
