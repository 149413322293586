// @ts-nocheck
import { put } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setNotifications } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";
import { rightResponseStatus } from "../../../config/api";

export function* getNotifications() {
  try {
    const response = yield secureCall(
      Constants.GET,
      ApiRoutes.NOTIFICATIONS,
      undefined,
      Constants.JSON
    );

    if (rightResponseStatus.includes(response.status))
      yield put(setNotifications(response.data));
  } catch (err) {}
}
