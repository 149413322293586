import { RoutesMap } from "./map";
import { Constants } from "../config/constants";
import Admin from "../pages/Admin";
import Consultant from "../pages/Consultant";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Purchases from "../pages/Purchase/Purchases";
import PurchaseDetails from "../pages/Purchase/Details";
import Sales from "../pages/Sale/Sales";
import OrderDetailsComponent from "../pages/Order/Details";
import SalePersist from "../pages/Sale/Persist";
import SaleDetails from "../pages/Sale/Details";
import ShowCase from "../pages/Purchase/ShowCase";
import PrivacyNotes from "./../pages/PrivacyNotes";

export default [
  {
    component: Admin,
    exact: true,
    isPrivate: true,
    path: RoutesMap.ADMIN,
    owner: Constants.ADMIN,
    roles: ["ADMIN, CONSULTANT"],
  },
  {
    component: Consultant,
    exact: true,
    isPrivate: true,
    path: RoutesMap.CONSULTANT,
    owner: Constants.CONSULTANT,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: Home,
    exact: true,
    isPrivate: false,
    path: RoutesMap.BASE,
    owner: null,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: PrivacyNotes,
    exact: true,
    isPrivate: false,
    path: RoutesMap.PRIVACY_NOTES,
    owner: null,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: PurchaseDetails,
    exact: true,
    isPrivate: true,
    path: RoutesMap.PURCHASE_DETAILS,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: Purchases,
    exact: true,
    isPrivate: true,
    path: RoutesMap.MY_PURCHASES,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: Purchases,
    exact: true,
    isPrivate: true,
    path: RoutesMap.PURCHASES,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: SaleDetails,
    exact: true,
    isPrivate: true,
    path: RoutesMap.SALE_DETAILS,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: Sales,
    exact: true,
    isPrivate: true,
    path: RoutesMap.MY_SALES,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: Sales,
    exact: true,
    isPrivate: true,
    path: RoutesMap.SALES,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: OrderDetailsComponent,
    exact: true,
    isPrivate: true,
    path: RoutesMap.ORDER,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: SalePersist,
    exact: true,
    isPrivate: true,
    path: RoutesMap.PERSIST_SALE,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: ShowCase,
    exact: true,
    isPrivate: false,
    path: RoutesMap.SHOW_CASE,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: Login,
    exact: true,
    isPrivate: true,
    path: RoutesMap.PURCHASE,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
  {
    component: Logout,
    exact: true,
    isPrivate: false,
    path: RoutesMap.LOGOUT,
    owner: Constants.USER,
    roles: ["ADMIN", "USER", "CONSULTANT"],
  },
];
