import { RoutesMap } from "../../routes/map";

export default {
  purchase: {
    ADMIN: {
      route: RoutesMap.PURCHASES,
      title: "Compras",
    },
    CONSULTANT: {
      route: RoutesMap.PURCHASES,
      title: "Compras",
    },
    USER: {
      route: RoutesMap.MY_PURCHASES,
      title: "Minhas compras",
    },
  },
  sale: {
    ADMIN: {
      route: RoutesMap.SALES,
      title: "Vendas",
    },
    CONSULTANT: {
      route: RoutesMap.SALES,
      title: "Vendas",
    },
    USER: {
      route: RoutesMap.MY_SALES,
      title: "Minhas vendas",
    },
  },
  order: {
    ADMIN: {},
    CONSULTANT: {},
    USER: {
      route: RoutesMap.ORDER,
      title: "Pedido"
    }
  }
};
