import { action } from "typesafe-actions";
import {
  Body,
  PurchaseTypes,
  GetCurrentManipulatedPurchaseBody,
  Purchase,
} from "./types";

// Purchase

export const createPurchase = () => action(PurchaseTypes.CREATE_PURCHASE);

export const createPurchaseFailure = () =>
  action(PurchaseTypes.CREATE_PURCHASE_FAILURE);

export const setPurchase = () => action(PurchaseTypes.SET_PURCHASE);

export const setPurchaseId = (saleId: string | number) =>
  action(PurchaseTypes.SET_SALE_ID, { saleId });

export const setTotal = (total: boolean) =>
  action(PurchaseTypes.SET_TOTAL, { total });

export const setValue = (value: string | number) =>
  action(PurchaseTypes.SET_VALUE, { value });

export const setDiscountPercentage = (discountPercentage: string | number) => {
  return action(PurchaseTypes.SET_DISCOUNT_PERCENTAGE, { discountPercentage });
}

export const getPurchases = (body: Body) =>
  action(PurchaseTypes.GET_PURCHASES, { ...body });

export const getPurchasesFailure = () =>
  action(PurchaseTypes.GET_PURCHASES_FAILURE);

export const setPurchases = (purchases: Response) =>
  action(PurchaseTypes.SET_PURCHASES, { purchases });

export const getCurrentManipulatedPurchase = (
  body: GetCurrentManipulatedPurchaseBody
) => action(PurchaseTypes.GET_CURRENT_MANIPULATED_PURCHASE, { ...body });

export const setCurrentManipulatedPurchase = (
  currentManipulatedPurchase: Purchase
) =>
  action(PurchaseTypes.SET_CURRENT_MANIPULATED_PURCHASE, {
    currentManipulatedPurchase,
  });

export const currentManipulatedPurchaseClean = () =>
  action(PurchaseTypes.CURRENT_MANIPULATED_PURCHASE_CLEAN);
export const purchaseProcessingClean = () =>
  action(PurchaseTypes.PURCHASE_PROCESSING_CLEAN);
export const purchaseClean = () => action(PurchaseTypes.PURCHASE_CLEAN);
