import { combineReducers } from "redux";

import admin from "./admin";
import auth from "./auth";
import credits from "./credits";
import documents from "./documents";
import files from "./files";
import history from "./history";
import icms from "./icms";
import location from "./location";
import modal from "./modal";
import notifications from "./notifications";
import pretention from "./pretention";
import purchase from "./purchase";
import rejections from "./rejections";
import roles from "./roles";
import sale from "./sale";
import status from "./status";
import steps from "./steps";
import signUp from "./signUp";
import toast from "./toast";
import user from "./user";
import privacyNotes from "./privacyNotes";

export default combineReducers({
  admin,
  auth,
  credits,
  documents,
  files,
  history,
  icms,
  location,
  modal,
  notifications,
  pretention,
  purchase,
  signUp,
  rejections,
  roles,
  sale,
  status,
  steps,
  toast,
  user,
  privacyNotes,
});
