import { action } from "typesafe-actions";
import { LocationTypes, Location, GetStatesBody } from "./types";

// Location

export const getRegions = () => action(LocationTypes.GET_REGIONS);

export const getRegionsFailure = () =>
  action(LocationTypes.GET_REGIONS_FAILURE);

export const setRegions = (regions: Location[]) =>
  action(LocationTypes.SET_REGIONS, { regions });

export const getStates = (body: GetStatesBody) =>
  action(LocationTypes.GET_STATES, { ...body });

export const getStatesFailure = () => action(LocationTypes.GET_STATES_FAILURE);

export const setStates = (states: Location[]) =>
  action(LocationTypes.SET_STATES, { states });

export const getCities = (stateCode: string) =>
  action(LocationTypes.GET_CITIES, { stateCode });

export const getCitiesFailure = () => action(LocationTypes.GET_CITIES_FAILURE);

export const setCities = (cities: Location[]) =>
  action(LocationTypes.SET_CITIES, { cities });

export const regionsClean = () => action(LocationTypes.REGIONS_CLEAN);
export const statesClean = () => action(LocationTypes.STATES_CLEAN);
export const citiesClean = () => action(LocationTypes.CITIES_CLEAN);
export const locationClean = () => action(LocationTypes.LOCATION_CLEAN);
