// @ts-nocheck
import { call, put, select, all } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";

import {
  setUsers,
  setCurrentManipulatedUser,
  setRejectReasons,
  setAdminAndAdvisors,
} from "./actions";
import { setToast } from "../toast/actions";
import { Constants } from "../../../config/constants";
import { getBodyAsFormData } from "../../../services/api/formData";
import dataMap from "./dataMap";
import { adminSelector } from "../selectors";
import { User, Roles } from "./types";
import { getFiles } from "../files/actions";
import { resetModal } from "../modal/actions";
import { secureCall } from "../interceptor";
import { rightResponseStatus } from "../../../config/api";

export function* getUsers(action: any) {
  try {
    const { company, email, name, page, size, verifiedUsers } = action.payload;
    let params: any = { page, size };

    if (email?.length) {
      params = { ...params, company, email, name };
    }

    if (verifiedUsers !== undefined) {
      params = { ...params, verifiedUsers };
    }

    const response = yield secureCall(Constants.GET, ApiRoutes.ADMIN_USERS, {
      params,
    });

    if (rightResponseStatus.includes(response.status)) {
      yield all([
        put(setUsers({ ...response.data, page, size })),
        put(resetModal()),
      ]);
    }
  } catch (err) {}
}

export function* persistUser(action: any) {
  try {
    const { payload } = action;
    const { method, id } = payload;

    const body = getBodyAsFormData(payload, dataMap);

    let response = { status: 500 };
    if (method === Constants.PUT) {
      response = yield secureCall(
        Constants.PUT,
        `${ApiRoutes.ADMIN_USERS}/${id}`,
        body,
        Constants.MULTIPART
      );
    } else {
      response = yield secureCall(
        Constants.POST,
        ApiRoutes.ADMIN_USERS,
        body,
        Constants.MULTIPART
      );
    }

    const message = dataMap.messages[method][response.status];

    if (rightResponseStatus.includes(response.status)) {
      yield call(getUsersAfterSuccess, message);
    }
  } catch (err) {}
}

export function* getCurrentManipulatedUser(action: any) {
  const {
    payload: { id },
  } = action;

  const {
    data: { rows },
  } = yield select(adminSelector);

  const currentManipulatedUser = rows.find((row: User) => row.id === id);
  yield all([
    put(setCurrentManipulatedUser(currentManipulatedUser)),
    put(getFiles({ companyId: currentManipulatedUser.companyId })),
  ]);
}

export function* deleteUser(action: any) {
  try {
    const {
      payload: { userId },
    } = action;
    yield secureCall(
      Constants.DELETE,
      `${ApiRoutes.ADMIN_USERS}/${userId}`,
      undefined,
      Constants.JSON,
      "Erro ao desabilitar usuário"
    );

    yield call(getUsersAfterSuccess, "Usuário desabilitado com sucesso!");
  } catch (err) {}
}

export function* setUserApproval(action: any) {
  try {
    const {
      payload: { userAction, userId, rejectReason, partnerId, advisorId },
    } = action;

    const body = userAction === Constants.REJECT ? { rejectReason } : { partnerId, advisorId };

    yield secureCall(
      Constants.POST,
      `${ApiRoutes.ADMIN_USERS}/${userId}/${userAction}`,
      body,
      Constants.JSON,
      "Erro ao validar/rejeitar usuário"
    );

    yield all([
      call(getUsersAfterSuccess, dataMap.messages[userAction].success),
    ]);
  } catch (err) {}
}

export function* getRejectReasons() {
  try {
    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ADMIN_USERS}${ApiRoutes.REJECT_REASONS}`
    );

    yield put(setRejectReasons(response.data));
  } catch (err) {}
}

function* getUsersAfterSuccess(message: string) {
  const {
    data: { page, size },
  } = yield select(adminSelector);
  const action = { payload: { page, size } };

  yield call(getUsers, action);
  yield put(
    setToast({
      message,
      severity: Constants.SUCCESS,
    })
  );
}

export function* getAdminAndAdvisors() {
  try {
    const roles = [Roles.ADMIN, Roles.ADVISOR]
    const response = yield secureCall(Constants.GET, ApiRoutes.ADMIN_USERS, {
      params: {
        page: 0,
        size: 100,
        roles
      }
    });

    if (rightResponseStatus.includes(response.status)) {
      yield put(setAdminAndAdvisors(response.data));
    }
  } catch (err) {}
}
