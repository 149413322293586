// @ts-nocheck
import { put, all, select, call } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import {
  setPurchase,
  purchaseProcessingClean,
  setPurchases,
  setCurrentManipulatedPurchase,
} from "./actions";
import { setToast } from "../toast/actions";
import { Constants } from "../../../config/constants";
import {
  purchaseSelector,
  creditsSelector,
  pretentionSelector,
} from "../selectors";
import { secureCall, failure } from "../interceptor";
import { resetModal } from "../modal/actions";
import { getFiles } from "../files/actions";
import { setHistory } from "../history/actions";
import { getNotifications } from "../notifications/actions";
import { rightResponseStatus } from "../../../config/api";

export function* createPurchase() {
  try {
    const { total, value, discountPercentage } = yield select(purchaseSelector);
    const { current } = yield select(creditsSelector);

    const response = yield secureCall(
      Constants.POST,
      ApiRoutes.ICMS_PURCHASE,
      {
        saleId: current.id,
        total,
        value,
        discountPercentage: parseFloat(discountPercentage),
      },
      Constants.JSON,
      "Falha ao realizar compra"
    );

    if (rightResponseStatus.includes(response.status)) {
      yield all([
        put(setPurchase()),
        put(resetModal()),
        put(
          setToast({
            message: "Compra realizada com sucesso!",
            severity: Constants.SUCCESS,
          })
        ),
        put(purchaseProcessingClean()),
      ]);
    }
  } catch (err) {}
}

export function* getPurchases(action: any) {
  try {
    const {
      payload: { page, size, status },
    } = action;

    const params = status ? { page, size, status } : { page, size };

    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ADMIN_ICMS}/${Constants.PURCHASES}`,
      { params }
    );
    if (rightResponseStatus.includes(response.status))
      yield put(setPurchases(response.data));
  } catch (err) {
    yield call(failure, undefined, "Falha ao realizar/atualizar compra");
  }
}

export function* getCurrentManipulatedPurchase(action: any) {
  try {
    const {
      payload: { id },
    } = action;

    const {
      data: { pretention },
    } = yield select(pretentionSelector);

    const currentPurchaseResponse = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS_PURCHASE}/${id}`
    );

    const currentManipulatedPurchase = currentPurchaseResponse.data;
    
    if (currentManipulatedPurchase?.seller) {
      currentManipulatedPurchase.sellerCompanyName = currentManipulatedPurchase.seller.company.name
    }
  
    yield all([
      put(setCurrentManipulatedPurchase(currentManipulatedPurchase)),
      put(getFiles({ orderId: currentManipulatedPurchase.orderId })),
      put(setHistory({ history: currentManipulatedPurchase.histories })),
      put(getNotifications()),
      put(resetModal()),
    ]);
  } catch (err) {}
}
