/* eslint-disable import/first */
import React from "react";
import { PrivacyNotesComponent } from "../../components";

const PrivacyNotes: React.FC = () => {
	return (
		<React.Suspense fallback="">
      <PrivacyNotesComponent />
		</React.Suspense>
	);
};

export default React.memo(PrivacyNotes);
