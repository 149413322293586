export enum PurchaseTypes {
  GET_CURRENT_MANIPULATED_PURCHASE = "@purchase/GET_CURRENT_MANIPULATED_PURCHASE",
  SET_CURRENT_MANIPULATED_PURCHASE = "@purchase/SET_CURRENT_MANIPULATED_PURCHASE",
  GET_PURCHASES = "@purchase/GET_PURCHASES",
  SET_PURCHASES = "@purchase/SET_PURCHASES",
  GET_PURCHASES_FAILURE = "@purchase/GET_PURCHASES_FAILURE",
  CREATE_PURCHASE = "@purchase/CREATE_PURCHASE",
  SET_PURCHASE = "@purchase/SET_PURCHASE",
  CREATE_PURCHASE_FAILURE = "@purchase/CREATE_PURCHASE_FAILURE",
  SET_SALE_ID = "@purchase/SET_SALE_ID",
  SET_TOTAL = "@purchase/SET_TOTAL",
  SET_VALUE = "@purchase/SET_VALUE",
  SET_DISCOUNT_PERCENTAGE = "@purchase/SET_DISCOUNT_PERCENTAGE",
  CURRENT_MANIPULATED_PURCHASE_CLEAN = "@purchase/CURRENT_MANIPULATED_PURCHASE_CLEAN",
  PURCHASE_CLEAN = "@purchase/PURCHASE_CLEAN",
  PURCHASE_PROCESSING_CLEAN = "@purchase/PURCHASE_PROCESSING_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface Body {
  page: string | number | undefined;
  size: string | number | undefined;
  status?: string | undefined;
}

export interface Response {
  hasNext: boolean | undefined;
  hasPrevious: boolean | undefined;
  pageCount: number | undefined;
  currentPage: number | undefined;
  rows: any | undefined;
  count: number | undefined;
  size: number;
  page: number;
}

export interface Purchase {
  id: number;
  saleId: number;
  value: string;
  saleValue: string;
  discount: string | number;
  discountedValue: string;
  validity: string | number;
  installmentsAmount: string | number;
  origin: string;
  market: string;
  city: string;
  status: string;
  region?: string;
  buyer?: {
    name: String;
    company: {
      name: String;
    }
  }
  // state?: string;
}

export interface GetCurrentManipulatedPurchaseBody {
  id: number;
}

export interface PurchaseState {
  readonly currentManipulatedPurchase: Purchase;
  readonly purchases: Response;
  readonly processing: boolean;
  readonly saleId: string | number;
  readonly total: boolean;
  readonly value: string | number;
  readonly loading: boolean;
}
