import { Reducer } from "redux";
import { RejectionsState, RejectionsTypes } from "./types";

const INITIAL_STATE: RejectionsState = {
  purchaseCancel: [],
  review: [],
  sale: [],
  saleCancel: [],
  seller: [],
  loading: false,
  error: false,
};

const reducer: Reducer<RejectionsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RejectionsTypes.GET_REJECTIONS:
      return { ...state, loading: true };
    case RejectionsTypes.SET_REJECTIONS:
      return {
        ...state,
        loading: false,
        error: false,
        purchaseCancel: action.payload.purchaseCancel,
        review: action.payload.review,
        sale: action.payload.sale,
        saleCancel: action.payload.saleCancel,
        seller: action.payload.seller,
      };
    case RejectionsTypes.GET_REJECTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        purchaseCancel: INITIAL_STATE.purchaseCancel,
        review: INITIAL_STATE.review,
        sale: INITIAL_STATE.sale,
        saleCancel: INITIAL_STATE.saleCancel,
        seller: INITIAL_STATE.seller,
      };
    case RejectionsTypes.REJECTIONS_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case RejectionsTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
