import { Reducer } from "redux";
import { LocationState, LocationTypes } from "./types";

const INITIAL_STATE: LocationState = {
  regions: [],
  states: [],
  cities: [],
  loading: false,
  error: false,
};

const reducer: Reducer<LocationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LocationTypes.GET_REGIONS:
      return { ...state, loading: true };
    case LocationTypes.SET_REGIONS:
      return {
        ...state,
        loading: false,
        error: false,
        regions: action.payload.regions,
      };
    case LocationTypes.GET_REGIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        regions: INITIAL_STATE.regions,
      };
    case LocationTypes.REGIONS_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE.regions,
      };

    case LocationTypes.GET_STATES:
      return { ...state, loading: true };
    case LocationTypes.SET_STATES:
      return {
        ...state,
        loading: false,
        error: false,
        states: action.payload.states,
      };
    case LocationTypes.GET_STATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        states: INITIAL_STATE.states,
      };
    case LocationTypes.STATES_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE.states,
      };

    case LocationTypes.GET_CITIES:
      return { ...state, loading: true };
    case LocationTypes.SET_CITIES:
      return {
        ...state,
        loading: false,
        error: false,
        cities: action.payload.cities,
      };
    case LocationTypes.GET_CITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        cities: INITIAL_STATE.cities,
      };
    case LocationTypes.CITIES_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE.cities,
      };

    case LocationTypes.LOCATION_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case LocationTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
