import { History, LocationState } from "history";

export enum AuthTypes {
  AUTH_REQUEST = "@auth/AUTH_REQUEST",
  AUTH_SUCCESS = "@auth/AUTH_SUCCESS",
  AUTH_FAILURE = "@auth/AUTH_FAILURE",
  AUTH_LOGOUT = "@auth/AUTH_LOGOUT",
  PERSISTENT_AUTH = "@auth/PERSISTENT_AUTH",
  AUTH_CLEAN = "@auth/AUTH_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface AuthBody {
  idTokenActiveDirectory: string;
  history: History<LocationState>;  
  fromNavbar?: boolean;
  instance?: any;
  userIp: string;
}

export interface PersistentAuth {
  history: History<LocationState>;
}

export interface AuthResponse {
  id: number;
  email: string;
  name: string;
  roles: string[];
  verifiedUser: boolean;
  privacyNotes?: PrivacyNotes;
}

export interface PrivacyNotes {
  version: string;
  accepted: boolean;
}

export interface AuthState {
  readonly data: AuthResponse;
  readonly loading: boolean;
  readonly status: boolean;
  readonly error: boolean;
}
