import { action } from "typesafe-actions";
import { PretentionTypes, Pretention } from "./types";

// Pretention
export const getPretention = (pretention: any) =>
  action(PretentionTypes.GET_PRETENTION, { pretention });

export const setPretention = (data: Pretention) =>
  action(PretentionTypes.SET_PRETENTION, { data });

export const resetPretention = () => action(PretentionTypes.RESET_PRETENTION);
