import { action } from "typesafe-actions";
import { RolesTypes, Roles } from "./types";

// Roles

export const getRoles = () => action(RolesTypes.GET_ROLES);

export const getRolesFailure = () => action(RolesTypes.GET_ROLES_FAILURE);

export const setRoles = (data: Roles) => action(RolesTypes.SET_ROLES, { data });

export const rolesClean = () => action(RolesTypes.ROLES_CLEAN);
