import { History, LocationState } from "history";

export enum CreditsTypes {
  GET_CURRENT_CREDIT = "@credits/GET_CURRENT_CREDIT",
  SET_CURRENT_CREDIT = "@credits/SET_CURRENT_CREDIT",
  SET_SHOW_CASE_TYPE = "@credits/SET_SHOW_CASE_TYPE",
  GET_REGIONS_CREDITS = "@credits/GET_REGIONS_CREDITS",
  SET_REGIONS_CREDITS = "@credits/SET_REGIONS_CREDITS",
  GET_REGIONS_CREDITS_FAILURE = "@credits/GET_REGIONS_CREDITS_FAILURE",
  GET_CREDITS = "@credits/GET_CREDITS",
  SET_CREDITS = "@credits/SET_CREDITS",
  GET_CREDITS_FAILURE = "@credits/GET_CREDITS_FAILURE",
  REGIONS_CREDITS_CLEAN = "@credits/REGIONS_CREDITS_CLEAN",
  CURRENT_CREDIT_CLEAN = "@credits/CURRENT_CREDIT_CLEAN",
  CREDITS_CLEAN = "@credits/CREDITS_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface RegionsCredit {
  state: string;
  value: string | number;
  discount: string | number;
  discountedValue: string | number;
}

export interface RegionsCredits {
  region: string;
  credits: RegionsCredit[];
}

export interface Credit {
  state: string;
  value: string | number;
  discount: number;
  discountedValue: string | number;
  validity: string | number;
  installmentsAmount: string | number;
  origin: string;
}

export interface GetCreditsBody {
  maxDiscount: string | number | undefined;
  minDiscount: string | number | undefined;
  page: string | number | undefined;
  region: string | number | undefined;
  size: string | number | undefined;
  state: string | number | undefined;
}

export interface GetCreditsResponse {
  hasNext: boolean | undefined;
  hasPrevious: boolean | undefined;
  pageCount: number | undefined;
  currentPage: number | undefined;
  rows: any | undefined;
  count: number | undefined;
  size: number;
  page: number;
}

export interface CreditsState {
  readonly credits: GetCreditsResponse;
  readonly regionsCredits: RegionsCredits[];
  readonly showCaseType: string;
  readonly current: Credit;
  readonly error: boolean;
  readonly loading: boolean;
}
