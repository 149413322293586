export enum FilesTypes {
  GET_FILES = "@files/GET_FILES",
  SET_FILES = "@files/SET_FILES",
  GET_FILES_FAILURE = "@files/GET_FILES_FAILURE",
  FILES_CLEAN = "@files/FILES_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface Body {
  companyId?: number | undefined;
  purchaseId?: number | undefined;
  saleId?: number | undefined;
  orderId?: number | undefined;
}

export interface File {
  description?: string;
  expirationDate: string;
  fileUrl: string;
  fileName?: string;
  fileTypeId?: string | number;
  type: string | number;
  scanned?: boolean;
  scannedStatus?: string;
}

export interface Files {
  files: File[];
}

export interface FilesState {
  readonly data: Files;
  readonly error: boolean;
  readonly loading: boolean;
}
