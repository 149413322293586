export enum LocationTypes {
  GET_REGIONS = "@location/GET_REGIONS",
  SET_REGIONS = "@location/SET_REGIONS",
  GET_REGIONS_FAILURE = "@location/GET_REGIONS_FAILURE",
  REGIONS_CLEAN = "@location/REGIONS_CLEAN",
  GET_STATES = "@location/GET_STATES",
  SET_STATES = "@location/SET_STATES",
  GET_STATES_FAILURE = "@location/GET_STATES_FAILURE",
  STATES_CLEAN = "@location/STATES_CLEAN",
  GET_CITIES = "@location/GET_CITIES",
  SET_CITIES = "@location/SET_CITIES",
  GET_CITIES_FAILURE = "@location/GET_CITIES_FAILURE",
  CITIES_CLEAN = "@location/CITIES_CLEAN",
  LOCATION_CLEAN = "@location/LOCATION_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface GetStatesBody {
  idAsValue?: boolean | undefined;
  regionId: string | number;
}

export interface Location {
  label: string;
  value: string;
}

export interface LocationState {
  readonly regions: Location[];
  readonly states: Location[];
  readonly cities: Location[];
  readonly error: boolean;
  readonly loading: boolean;
}
