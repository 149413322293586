// TODO: Break admin duck in users, admin, and currentUser ducks
export enum AdminTypes {
  PERSIST_USER = "@admin/PERSIST_USER",
  DELETE_USER = "@admin/DELETE_USER",
  GET_USERS = "@admin/GET_USERS",
  SET_USERS = "@admin/SET_USERS",
  GET_REJECT_REASONS = "@admin/GET_REJECT_REASONS",
  SET_REJECT_REASONS = "@admin/SET_REJECT_REASONS",
  GET_USERS_FAILURE = "@admin/GET_USERS_FAILURE",
  GET_CURRENT_MANIPULATED_USER = "@admin/GET_CURRENT_MANIPULATED_USER",
  SET_CURRENT_MANIPULATED_USER = "@admin/SET_CURRENT_MANIPULATED_USER",
  CURRENT_MANIPULATED_USER_CLEAN = "@admin/CURRENT_MANIPULATED_USER_CLEAN",
  SET_PAGE = "@admin/SET_PAGE",
  SET_SIZE = "@admin/SET_SIZE",
  SET_USER_APPROVAL = "@admin/SET_USER_APPROVAL",
  GET_ADMIN_AND_ADVISORS = "@admin/GET_ADMIN_AND_ADVISORS",
  SET_ADMIN_AND_ADVISORS = "@admin/SET_ADMIN_AND_ADVISORS",
  ADMIN_CLEAN = "@admin/ADMIN_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export enum Roles {
  ADMIN = 1,
  USER = 2,
  ADVISOR = 3,
}

export interface User {
  id: number;
  createdAt: string;
  companyId: number;
  name: string;
  occupation: string;
  socialReason: string;
  cnpj: string;
  email: string;
  company: string;  
  phoneNumber: string;
  attorneyLetter?: string;
  legalRepresentativeName?: string;
  roles: string[];
  deleted: boolean;
  verifiedUser: boolean;
  partner?: {
    id: number;
    name: string,
    email: string
  };
  advisor?: {
    id: number;
    name: string,
    email: string
  };
}

export interface PersistUserBody extends User {  
  method: string;
}

export interface GetUsersBody {
  company?: string | undefined;
  email?: string | undefined;
  name?: string | undefined;
  page?: number | undefined;
  size?: number | undefined;
  verifiedUsers?: boolean | undefined;
}

export interface AdminResponse {
  hasNext: boolean | undefined;
  hasPrevious: boolean | undefined;
  pageCount: number | undefined;
  currentPage: number | undefined;
  rows: any | undefined;
  count: number | undefined;
  size: number;
  page: number;
}

export interface RejectReason {
  label: string;
  value: string;
}

export interface UserApprovalBody {
  userAction: string;
  userId: number;
  rejectReason?: string;
  partnerId?: any;
  advisorId?: any;
}

export interface AdminState {
  readonly rejectReasons: RejectReason[];
  readonly currentManipulatedUser: User;
  readonly data: AdminResponse;
  readonly adminAndAdvisors: AdminResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
