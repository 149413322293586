// @ts-nocheck
import { call, put, all, select, delay } from "redux-saga/effects";

import { authLogout } from "./auth/actions";
import { setToast } from "./toast/actions";
import { Constants } from "../../config/constants";
import { authSelector } from "./selectors";
import { axiosInstance } from "../../services/api";
import { ApiRoutes } from "../../config/api/routes";
import { headers } from "../../config/api";

export function* secureCall(
  method: string,
  route: string,
  args: any = undefined,
  contentType: string = Constants.JSON,
  errorMessage: string = "",
  errorCallback: any = () => {},
  baseUrl: string = process.env.REACT_APP_BASE_URL || ApiRoutes.PROD
) {
  try {
    const api = axiosInstance(baseUrl, {
      ...headers[contentType]
    });

    const response = yield call(api[method], route, args);

    return response;
  } catch (err) {
    if (err.response.status === 401) {
      yield call(logoutExpiratedUser, err.response.status);
    } else {
      if (errorMessage) yield call(failure, errorCallback, errorMessage);
    }
  }
}

export function* secureCallApi(
  method: string,
  route: string,
  args: any = undefined,  
  errorMessage: string = "",
  errorCallback: any = () => {},
  baseUrl: string = process.env.REACT_APP_BASE_URL || ApiRoutes.PROD
) {
  try {
    const api = axiosInstance(baseUrl, args?.headers);

    const response = yield call(api[method], route, args?.body);

    return response;
  } catch (err) {
    if (err.response.status === 401) {
      yield call(logoutExpiratedUser, err.response.status);
    } else {
      if (errorMessage) yield call(failure, errorCallback, errorMessage);
    }
  }
}

export function* failure(
  failureCallback: Function | undefined,
  message: string
) {
  if (failureCallback) yield put(failureCallback());
  yield put(
    setToast({
      message,
      severity: Constants.ERROR,
      error: true,
    })
  );
}

export function* logoutExpiratedUser(responseStatus: number) {
  if (responseStatus === 401) {
    const { status } = yield select(authSelector);
    if (status) {
      yield put(authLogout());
      yield put(
        setToast({
          message: "Sessão expirada, é necessário fazer o login novamente",
          severity: Constants.ERROR,
          error: true,
        })
      );
    }
  }
}
