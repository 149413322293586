import { Reducer } from "redux";
import { PurchaseState, PurchaseTypes } from "./types";

const INITIAL_STATE: PurchaseState = {
  purchases: {
    hasNext: undefined,
    hasPrevious: undefined,
    pageCount: undefined,
    currentPage: undefined,
    rows: [],
    count: undefined,
    page: 0,
    size: 10,
  },
  currentManipulatedPurchase: {
    id: 0,
    saleId: 0,
    value: "",
    saleValue: "",
    discount: 0,
    discountedValue: "",
    validity: "",
    installmentsAmount: "",
    origin: "",
    market: "",
    city: "",
    status: "",
  },
  processing: false,
  saleId: 0,
  total: true,
  value: "0",
  loading: false,
};

const reducer: Reducer<PurchaseState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PurchaseTypes.SET_PURCHASE:
      return {
        ...state,
        processing: true,
      };
    case PurchaseTypes.CREATE_PURCHASE_FAILURE:
      return {
        ...state,
        processing: false,
      };

    case PurchaseTypes.SET_SALE_ID:
      return {
        ...state,
        saleId: action.payload.saleId,
      };
    case PurchaseTypes.SET_TOTAL:
      return {
        ...state,
        total: action.payload.total,
      };
    case PurchaseTypes.SET_VALUE:
      return {
        ...state,
        value: action.payload.value,
      };
    case PurchaseTypes.SET_DISCOUNT_PERCENTAGE:
      return {
        ...state,
        discountPercentage: action.payload.discountPercentage,
      };
    case PurchaseTypes.GET_PURCHASES:
      return { ...state, loading: true };
    case PurchaseTypes.SET_PURCHASES:
      return {
        ...state,
        loading: false,
        error: false,
        purchases: action.payload.purchases,
      };
    case PurchaseTypes.GET_PURCHASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        purchases: INITIAL_STATE.purchases,
      };

    case PurchaseTypes.GET_CURRENT_MANIPULATED_PURCHASE:
      return {
        ...state,
        loading: true,
      };
    case PurchaseTypes.SET_CURRENT_MANIPULATED_PURCHASE:
      return {
        ...state,
        loading: false,
        error: false,
        currentManipulatedPurchase: action.payload.currentManipulatedPurchase,
      };

    case PurchaseTypes.CURRENT_MANIPULATED_PURCHASE_CLEAN:
      return {
        ...state,
        loading: false,
        currentManipulatedPurchase: INITIAL_STATE.currentManipulatedPurchase,
      };
    case PurchaseTypes.PURCHASE_PROCESSING_CLEAN:
      return {
        ...state,
        processing: INITIAL_STATE.processing,
      };

    case PurchaseTypes.PURCHASE_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case PurchaseTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
