import { action } from "typesafe-actions";
import { SignUpTypes, SignUpBody, SignUp } from "./types";

// SignUp

export const signUpRequest = (signUpBody: SignUpBody) =>
  action(SignUpTypes.SIGN_UP_REQUEST, { ...signUpBody });

export const signUpSuccess = (data: SignUp) =>
  action(SignUpTypes.SIGN_UP_SUCCESS, { data });

export const signUpFailure = () => action(SignUpTypes.SIGN_UP_FAILURE);
export const signUpClean = () => action(SignUpTypes.SIGN_UP_CLEAN);
