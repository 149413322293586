// @ts-nocheck
import { put } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setHistory } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";
import { rightResponseStatus } from "../../../config/api";

export function* getHistory(action: any) {
  try {
    const {
      payload: { id, pretention },
    } = action;

    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS}/${pretention}/${id}/${Constants.HISTORY}`
    );
    if (rightResponseStatus.includes(response.status))
      yield put(setHistory({ history: response.data }));
  } catch (err) {}
}
