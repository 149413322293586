import { action } from "typesafe-actions";
import { PrivacyNotesTypes, PrivacyNotes } from "./types";

export const getPrivacyNotes = () => action(PrivacyNotesTypes.GET_PRIVACY_NOTES);

export const setPrivacyNotes = (data: PrivacyNotes) => action(PrivacyNotesTypes.SET_PRIVACY_NOTES, { data });

export const getAcceptPrivacyNotes = () => action(PrivacyNotesTypes.GET_ACCEPT_PRIVACY_NOTES);

export const setAcceptPrivacyNotes = (accepted: boolean) => action(PrivacyNotesTypes.SET_ACCEPTED, { accepted });
