export enum RejectionsTypes {
  GET_REJECTIONS = "@rejections/GET_REJECTIONS",
  SET_REJECTIONS = "@rejections/SET_REJECTIONS",
  GET_REJECTIONS_FAILURE = "@rejections/GET_REJECTIONS_FAILURE",
  REJECTIONS_CLEAN = "@rejections/REJECTIONS_CLEAN",
  CLEAN_ALL = "@app/CLEAN_all",
}

export interface Rejection {
  label: string;
  value: string;
}

export interface Rejections {
  purchaseCancel: Rejection[];
  review: Rejection[];
  sale: Rejection[];
  saleCancel: Rejection[];
  seller: Rejection[];
}

export interface RejectionsState {
  readonly purchaseCancel: Rejection[];
  readonly review: Rejection[];
  readonly sale: Rejection[];
  readonly saleCancel: Rejection[];
  readonly seller: Rejection[];
  readonly error: boolean;
  readonly loading: boolean;
}
