// @ts-nocheck
import { put } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setRegions, setStates, setCities } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";
import { rightResponseStatus } from "../../../config/api";

export function* getRegions() {
  try {
    const response = yield secureCall(Constants.GET, `${ApiRoutes.REGIONS}`);

    if (rightResponseStatus.includes(response.status))
      yield put(setRegions(response.data));
  } catch (err) {}
}

export function* getStates(action: any) {
  try {
    const {
      payload: { idAsValue, regionId },
    } = action;

    const params = { idAsValue };

    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.REGIONS}/${regionId}/${Constants.STATES}`,
      { params }
    );

    if (rightResponseStatus.includes(response.status))
      yield put(setStates(response.data));
  } catch (err) {}
}

export function* getCities(action: any) {
  try {
    const {
      payload: { stateCode },
    } = action;

    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.STATES}/${stateCode}/${Constants.CITIES}`
    );

    if (rightResponseStatus.includes(response.status))
      yield put(setCities(response.data));
  } catch (err) {}
}
