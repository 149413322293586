// @ts-nocheck
import { put } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setUserSales, setUserPurchases } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";

export function* getUserSalesOrPurchases(action: any) {
  try {
    const { page, size, pretention, status } = action.payload;
    const params = status ? { page, size, status } : { page, size };

    const map = {
      purchase: {
        route: `${ApiRoutes.ICMS}/${Constants.USER_PURCHASES}`,
        handler: setUserPurchases,
      },
      sale: {
        route: `${ApiRoutes.ICMS}/${Constants.USER_SALES}`,
        handler: setUserSales,
      },
    };

    const { handler, route } = map[pretention];

    const response = yield secureCall(Constants.GET, route, {
      params,
    });

    yield put(handler({ ...response.data, page, size }));
  } catch (err) {}
}
