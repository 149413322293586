import { History, LocationState } from "history";

export enum SaleTypes {
  GET_CURRENT_MANIPULATED_SALE = "@sale/GET_CURRENT_MANIPULATED_SALE",
  SET_CURRENT_MANIPULATED_SALE = "@sale/SET_CURRENT_MANIPULATED_SALE",
  GET_CURRENT_MANIPULATED_ORDER_ID = "@sale/GET_CURRENT_MANIPULATED_ORDER_ID",
  SET_CURRENT_MANIPULATED_ORDER_ID = "@sale/SET_CURRENT_MANIPULATED_ORDER_ID",
  GET_SALES = "@sale/GET_SALES",
  SET_SALES = "@sale/SET_SALES",
  GET_SALES_FAILURE = "@sale/GET_SALES_FAILURE",
  PERSIST_SALE = "@sale/PERSIST_SALE",
  SET_SALE = "@sale/SET_SALE",
  PERSIST_SALE_FAILURE = "@sale/PERSIST_SALE_FAILURE",
  SALE_CLEAN = "@sale/SALE_CLEAN",
  CURRENT_MANIPULATED_SALE_CLEAN = "@sale/CURRENT_MANIPULATED_SALE_CLEAN",
  SALES_CLEAN = "@sale/SALES_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface SaleBody {
  id?: number;
  regionId: string | number;
  stateCode: string;
  city: string;
  region: string;
  state: string;
  marketId: string | number;
  value: string | number;
  discount: string | number;
  installmentsAmount: string | number;
  validity: string | number;
  originId: string | number;
  declarations: any;
  declarationFiles: any;
  history?: History<LocationState>;
  saleId?: number;
  status: string;
}

export interface SaleCreateResponse {
  image: string;
  status: boolean;
}

export interface Body {
  page: string | number | undefined;
  size: string | number | undefined;
  status?: string | undefined;
}

export interface Response {
  hasNext: boolean | undefined;
  hasPrevious: boolean | undefined;
  pageCount: number | undefined;
  currentPage: number | undefined;
  rows: any | undefined;
  count: number | undefined;
  size: number;
  page: number;
}

export interface Sale {
  id: number;
  value: string;
  discount: string | number;
  discountedValue: string;
  validity: string | number;
  installmentsAmount: string | number;
  origin: string;
  originId: number;
  market: string;
  marketId: number;
  region?: string;
  state?: string;
  stateCode: string;
  city: string;
  status: string;
  orders?: any[];
  seller?: {
    company: {
      name: String;
    }
  };
}

export interface GetCurrentManipulatedSaleBody {
  id: number;
  orderId?: any
}
export interface SaleState {
  readonly currentManipulatedSale: Sale;
  readonly data: SaleCreateResponse;
  readonly sales: Response;
  readonly error: boolean;
  readonly loading: boolean;
  readonly orderId: string | number;
}
