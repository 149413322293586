export enum RoutesMap {
  BASE = "/",
  ADMIN = "/admin",
  PURCHASE = "/comprar",
  CONSULTANT = "/consultor",
  MY_PURCHASES = "/minhas-compras",
  MY_SALES = "/minhas-vendas",
  PURCHASES = "/compras",
  PURCHASE_DETAILS = "/detalhes-compra",
  TESTS = "/testes",
  PERSIST_SALE = "/criar-venda",
  SHOW_CASE = "/creditos",
  SALES = "/vendas",
  SALE_DETAILS = "/detalhes-venda",
  SALE = "/vender",
  ORDER = "/detalhes-pedido",
  LOGOUT = "/logoff",
  PRIVACY_NOTES = "/termos-privacidade"
}
