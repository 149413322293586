import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { injectable } from "inversify";
import "reflect-metadata";
const browserHistory = createBrowserHistory({ basename: '' });

export interface ILogService {
    registerException(errorMessage: string) : void;

    registerDebug(message: string): void;

    registerTrace(message: string): void;

    registerAudit(userEmail: string, userIp: string, message: string): void;
}

@injectable()
export default class LogService implements ILogService {    
    private appInsights: ApplicationInsights;
    constructor() {
        var reactPlugin = new ReactPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
                extensions: [reactPlugin],
                extensionConfig: {
                  [reactPlugin.identifier]: { history: browserHistory }
                }
            }
        });
        this.appInsights.loadAppInsights();
    }
    registerException(errorMessage: string) {        
        this.appInsights.trackException({exception: new Error(errorMessage)});
    }

    registerDebug(message: string) {
        console.debug(message);
        this.appInsights.trackEvent({name:"debug", 
        properties: {
            message: message
        }})        
    }

    registerTrace(message: string) {
        console.trace(message);        
        this.appInsights.trackTrace({message: message});
    }

    registerAudit(userEmail: string, userIp: string, message: string) {
        this.appInsights.trackEvent({name:"auditlog", 
        properties: {
            userEmail: userEmail,
            userIp: userIp,
            message: message
        }})        
    }
}
