export enum PrivacyNotesTypes {
  GET_PRIVACY_NOTES = "@privacyNotes/GET_PRIVACY_NOTES",
  SET_PRIVACY_NOTES = "@privacyNotes/SET_PRIVACY_NOTES",
  GET_ACCEPT_PRIVACY_NOTES = "@privacyNotes/GET_ACCEPT_PRIVACY_NOTES",
  SET_ACCEPTED = "@privacyNotes/SET_ACCEPTED",
}

export interface PrivacyNotes {
  id: Number;
  term: string;
  hasAccepted: boolean;
}

export interface PrivacyNotesState {
  readonly data: PrivacyNotes;
}
