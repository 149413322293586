export enum ModalTypes {
  SET_MODAL = "@modal/SET_MODAL",
  RESET_MODAL = "@modal/RESET_MODAL",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface Modal {
  animation?: boolean;
  body: string;
  centered?: boolean;
  footer?: any;
  scrollable?: boolean;
  size?: any;
  title?: string | undefined;
}

export interface ModalState {
  readonly data: Modal;
  readonly show: boolean;
}
