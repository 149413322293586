// @ts-nocheck
import { put } from "redux-saga/effects";

import pretentionConfig from "../../../config/pretention";
import { setPretention } from "./actions";

export function* getPretention(action: any) {
  const {
    payload: { pretention },
  } = action;
  const { color } = pretentionConfig[pretention];
  yield put(setPretention({ pretention, color }));
}
