/* eslint-disable import/first */
import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { RoutesMap } from "../../routes/map";
import { Redirect } from "react-router-dom";

const Logout: React.FC = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        const email = instance.getAllAccounts()[0].username;
        const currentAccount = instance.getAccountByUsername(email);
        const logoutParams = {
          account: currentAccount,
          onRedirectNavigate: () => true
        }
        instance.logoutRedirect(logoutParams);
    }

    return (
        <React.Suspense fallback="">
            <Redirect to={RoutesMap.BASE} />
        </React.Suspense>
    );
};

export default React.memo(Logout);