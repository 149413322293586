import { Reducer } from "redux";
import { ToastState, ToastTypes } from "./types";

import { Constants } from "../../../config/constants";

const INITIAL_STATE: ToastState = {
  data: {
    message: "",
    severity: Constants.SUCCESS,
    error: false,
  },
  open: false,
};

const reducer: Reducer<ToastState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ToastTypes.SET_TOAST:
      return {
        ...state,
        open: true,
        data: action.payload.data,
      };
    case ToastTypes.RESET_TOAST:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case ToastTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
