export enum ToastTypes {
  SET_TOAST = "@toast/SET_TOAST",
  RESET_TOAST = "@toast/RESET_TOAST",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface Toast {
  message: string;
  severity: string;
  error?: boolean;
}

export interface ToastState {
  readonly data: Toast;
  readonly open: boolean;
}
