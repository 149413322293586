import { Reducer } from "redux";
import { AuthState, AuthTypes } from "./types";

const INITIAL_STATE: AuthState = {
  data: {
    id: 0,
    email: "",
    roles: [""],
    name: "",
    verifiedUser: false,
  },
  error: false,
  status: false,
  loading: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.AUTH_REQUEST:
      return { ...state, loading: true };
    case AuthTypes.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        status: true,
        data: action.payload.data,
      };
    case AuthTypes.AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        status: false,
        data: INITIAL_STATE.data,
      };
    case AuthTypes.AUTH_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case AuthTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
