export enum DocumentsTypes {
  GET_DOCUMENTS = "@documents/GET_DOCUMENTS",
  SET_DOCUMENTS = "@documents/SET_DOCUMENTS",
  GET_DOCUMENTS_FAILURE = "@documents/GET_DOCUMENTS_FAILURE",
  DOCUMENTS_CLEAN = "@documents/DOCUMENTS_CLEAN",
  CLEAN_ALL = "@app/CLEAN_all",
}

export interface Documents {
  documents: any;
}

export interface DocumentsState {
  readonly data: Documents;
  readonly error: boolean;
  readonly loading: boolean;
}
