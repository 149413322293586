export enum HistoryTypes {
  GET_HISTORY = "@history/GET_HISTORY",
  SET_HISTORY = "@history/SET_HISTORY",
  GET_HISTORY_FAILURE = "@history/GET_HISTORY_FAILURE",
  HISTORY_CLEAN = "@history/HISTORY_CLEAN",
  CLEAN_ALL = "@app/CLEAN_all",
}

export interface Body {
  id: string | number;
  pretention: string;
}

export interface HistoryLog {
  status: string;
  createdAt: string;
}

export interface History {
  history: HistoryLog[];
}

export interface HistoryState {
  readonly data: History;
  readonly error: boolean;
  readonly loading: boolean;
}
