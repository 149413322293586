import { Reducer } from "redux";
import { CreditsState, CreditsTypes } from "./types";
import Images from "../../../config/images";
import { Constants } from "../../../config/constants";

const INITIAL_STATE: CreditsState = {
  regionsCredits: [
    {
      region: "",
      credits: [{ state: "", value: "", discount: 0, discountedValue: "" }],
    },
  ],
  credits: {
    hasNext: undefined,
    hasPrevious: undefined,
    pageCount: undefined,
    currentPage: undefined,
    rows: [],
    count: undefined,
    page: 0,
    size: 6,
  },
  current: {
    state: "",
    value: "",
    discount: 0,
    discountedValue: "",
    validity: 0,
    installmentsAmount: 0,
    origin: "",
  },
  showCaseType: Constants.PUBLIC,
  loading: false,
  error: false,
};

const reducer: Reducer<CreditsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreditsTypes.GET_REGIONS_CREDITS:
      return { ...state, loading: true };
    case CreditsTypes.SET_REGIONS_CREDITS:
      return {
        ...state,
        loading: false,
        error: false,
        regionsCredits: action.payload.regionsCredits,
      };
    case CreditsTypes.GET_REGIONS_CREDITS_FAILURE:
      return {
        ...state,
        regionsCredits: INITIAL_STATE.regionsCredits,
      };

    case CreditsTypes.GET_CREDITS:
      return { ...state, loading: true };
    case CreditsTypes.SET_CREDITS:
      return {
        ...state,
        loading: false,
        error: false,
        credits: action.payload.credits,
      };
    case CreditsTypes.GET_CREDITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        credits: INITIAL_STATE.credits,
      };

    case CreditsTypes.SET_SHOW_CASE_TYPE:
      return {
        ...state,
        showCaseType: action.payload.showCaseType,
      };

    case CreditsTypes.SET_CURRENT_CREDIT:
      return {
        ...state,
        current: action.payload.current,
      };
    case CreditsTypes.CURRENT_CREDIT_CLEAN:
      return {
        ...state,
        current: INITIAL_STATE.current,
      };

    case CreditsTypes.CURRENT_CREDIT_CLEAN:
      return {
        ...state,
        current: INITIAL_STATE.current,
      };

    case CreditsTypes.REGIONS_CREDITS_CLEAN:
      return {
        ...state,
        regionsCredits: INITIAL_STATE.regionsCredits,
      };

    case CreditsTypes.CREDITS_CLEAN:
      return {
        ...state,
        credits: INITIAL_STATE.credits,
      };
    case CreditsTypes.CLEAN_ALL:
      return {
        ...state,
        credits: INITIAL_STATE.credits,
        current: INITIAL_STATE.current,
        showCaseType: INITIAL_STATE.showCaseType,
      };
    default:
      return state;
  }
};

export default reducer;
