export const Main = {
  colors: {
    activeTab: "#F1F3F5",
    background: "#FAFAFA",
    black: "#000000",
    borderAndDisabled: "#CDCDCD",
    formError: "#DA1F26",
    inputTexts: "#9E9E9E",
    modalText: "#6C6C73",
    navBar: "#30323E",
    navButton: "#F8BA33",
    primaryButtonAndLabels: "#47627E",
    proposalConfigButton: "#CCCCCC",
    secondaryButtonAndLabels: "#6D597A",
    transparent: "transparent",
    validUser: "#007331",
    white: "#FFFFFF",
  },
  fontSize: {
    fourteenPx: "0.875em",
    sisteenPx: "1em",
    eighteenPx: "1.125em",
    twentyPx: "1.250em",
    twentyTwoPx: "1.375em",
    twentyFourPx: "1.500em",
    twentySixPx: "1.625em",
    twentyEightPx: "1.750em",
    thirtyPx: "1.875em",
    thirtyTwoPx: "2em",
    fourtyPx: "2.5em",
  },
  border: {
    radius: {
      small: "4px",
      medium: "12px",
      high: "16px",
    },
  },
};
