import { ApplicationState } from "..";

export const adminSelector = (state: ApplicationState) => state.admin;
export const authSelector = (state: ApplicationState) => state.auth;
export const creditsSelector = (state: ApplicationState) => state.credits;
export const icmsSelector = (state: ApplicationState) => state.icms;
export const locationSelector = (state: ApplicationState) => state.location;
export const pretentionSelector = (state: ApplicationState) => state.pretention;
export const purchaseSelector = (state: ApplicationState) => state.purchase;
export const saleSelector = (state: ApplicationState) => state.sale;
export const statusSelector = (state: ApplicationState) => state.status;
export const userSelector = (state: ApplicationState) => state.user;
export const toastSelector = (state: ApplicationState) => state.toast;
export const privacyNotesSelector = (state: ApplicationState) => state.privacyNotes;
