/* eslint-disable import/first */
 import React from "react";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { ReactLazyPreload } from "./config/lazyload";
import GlobalStyles from "./styles/global";
import store, { persistor } from "./store";

//IOC configurations
import { container } from './ioc';
import { ProviderIoc} from './ioc.react';
const Routes = ReactLazyPreload(() => import("./routes"));
const App: React.FC = () => (
  <ProviderIoc container={container}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Suspense fallback="">
            <title>{ process.env.REACT_APP_RELEASE_VERSION }</title>
            <Routes />
          </React.Suspense>
        </PersistGate>
      </Provider>
      <GlobalStyles />
    </BrowserRouter>
  </ProviderIoc>
);

export default React.memo(App);
