// @ts-nocheck
import { put, all } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setMarkets, setFileTypes, setOrigins } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";
import { rightResponseStatus } from "../../../config/api";

export function* getMarkets() {
  try {
    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS}/${Constants.MARKETS}`
    );

    if (rightResponseStatus.includes(response.status))
      yield put(setMarkets(response.data));
  } catch (err) {}
}

export function* getFileTypes(action: any) {
  try {
    const {
      payload: { stateCode },
    } = action;

    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS}/${Constants.FILE_TYPES}/${stateCode}`
    );

    if (rightResponseStatus.includes(response.status))
      yield put(setFileTypes(response.data));
  } catch (err) {}
}

export function* getOrigins() {
  try {
    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS}/${Constants.ORIGINS}`
    );

    if (rightResponseStatus.includes(response.status))
      yield all([put(setOrigins(response.data))]);
  } catch (err) {}
}
