export enum RolesTypes {
  GET_ROLES = "@roles/GET_ROLES",
  SET_ROLES = "@roles/SET_ROLES",
  GET_ROLES_FAILURE = "@roles/GET_ROLES_FAILURE",
  ROLES_CLEAN = "@roles/ROLES_CLEAN",
  CLEAN_ALL = "@app/CLEAN_all",
}

export interface Role {
  label: string;
  value: string;
}

export interface Roles {
  roles: Role[];
}

export interface RolesState {
  readonly data: Roles;
  readonly error: boolean;
  readonly loading: boolean;
}
