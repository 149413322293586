// @ts-nocheck
import { put } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { signUpSuccess } from "./actions";
import { setToast } from "../toast/actions";
import { Constants } from "../../../config/constants";
import Images from "../../../config/images";
import { getBodyAsFormData } from "../../../services/api/formData";

import dataMap from "./dataMap";
import { rightResponseStatus } from "../../../config/api";
import { secureCall } from "../interceptor";

export function* signUp(action: any) {
  try {
    const { payload } = action;

    const body = getBodyAsFormData(payload.body, dataMap);

    const response = yield secureCall(
      Constants.POST,
      ApiRoutes.SIGN_UP,
      body,
      Constants.MULTIPART,
      "Erro ao realizar cadastro, verifique seus dados ou entre em contato com o administrador",
      payload.callback,
      undefined
    );

    const message = dataMap.messages[response.status];
    if (rightResponseStatus.includes(response.status)) {
      yield put(
        setToast({
          message,
          severity: Constants.SUCCESS,
        })
      );
      yield put(signUpSuccess({ image: Images.FINISHED_RIGHT, status: true }));
    }
  } catch (err) {}
}
