import { Reducer } from "redux";
import { NotificationsState, NotificationsTypes } from "./types";

const INITIAL_STATE: NotificationsState = {
  data: {
    purchases: 0,
    sales: 0,
    users: 0,
  },
  loading: false,
  error: false,
};

const reducer: Reducer<NotificationsState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case NotificationsTypes.GET_NOTIFICATIONS:
      return { ...state, loading: true };
    case NotificationsTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case NotificationsTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: INITIAL_STATE.data,
      };
    case NotificationsTypes.NOTIFICATIONS_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case NotificationsTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
