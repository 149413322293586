// @ts-nocheck
import { call, put, all, select, take } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setRegionsCredits, setCurrentCredit, setCredits } from "./actions";
import { setToast } from "../toast/actions";
import { creditsSelector, locationSelector } from "../selectors";
import { Constants } from "../../../config/constants";
import { Sale } from "../sale/types";
import { secureCall } from "../interceptor";
import { getStates } from "../location/actions";
import { LocationTypes } from "../location/types";
import { getValueForRegionOrState } from "../../../services/api/location";
import { rightResponseStatus } from "../../../config/api";

export function* getCurrentCredit(action: any) {
  const {
    payload: { id },
  } = action;
  const {
    credits: { rows },
  } = yield select(creditsSelector);

  const credit = rows.find((row: Sale) => row.id === id);

  yield put(setCurrentCredit(credit));
}

export function* getCredits(action: any) {
  try {
    const {
      payload: { maxDiscount, minDiscount, page, region, size, state },
    } = action;

    const { regionId, stateId } = yield call(
      getRegionAndStateId,
      region,
      state
    );

    let params: any = { maxDiscount, minDiscount, page, size };

    if (regionId) params = { ...params, regionId };
    if (stateId) params = { ...params, stateId };

    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS}/${Constants.SHOW_CASE}/${Constants.PRIVATE}`,
      { params }
    );

    if (rightResponseStatus.includes(response.status)) {
      yield all([
        put(setCredits(response.data)),
        put(
          setToast({
            message: "Créditos carregados com sucesso!",
            severity: Constants.SUCCESS,
          })
        ),
      ]);
    }
  } catch (err) {}
}

function* getRegionAndStateId(region: string | number, state: string | number) {
  if (region) {
    const { regions } = yield select(locationSelector);
    const regionId = getValueForRegionOrState(regions, region);

    let { states } = yield select(locationSelector);
    if (!states.length || typeof states[0]?.value === Constants.STRING) {
      yield put(getStates({ idAsValue: true, regionId }));
      const location = yield select(locationSelector);
      states = location.states;
      yield take(LocationTypes.SET_STATES);
    }

    const stateId = getValueForRegionOrState(states, state);
    return { regionId, stateId };
  } else {
    return { regionId: undefined, stateId: undefined };
  }
}

export function* getRegionsCredits() {
  try {
    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS}/${Constants.SHOW_CASE}`
    );
    if (rightResponseStatus.includes(response.status))
      yield put(setRegionsCredits(response.data));
  } catch (err) {}
}
