// @ts-nocheck
import { call, put, all } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { api, mockApi, localApi } from "../../../services/api";
import { setRoles } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";

export function* getRoles() {
  try {
    const response = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ADMIN_ROLES}`
    );

    yield put(setRoles({ roles: response.data }));
  } catch (err) {}
}
