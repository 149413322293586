import { action } from "typesafe-actions";
import { IcmsTypes, Icms } from "./types";

// Icms

export const getMarkets = () => action(IcmsTypes.GET_MARKETS);

export const getMarketsFailure = () => action(IcmsTypes.GET_MARKETS_FAILURE);

export const setMarkets = (markets: Icms[]) =>
  action(IcmsTypes.SET_MARKETS, { markets });

export const getFileTypes = (stateCode: string) =>
  action(IcmsTypes.GET_FILE_TYPES, { stateCode });

export const getFileTypesFailure = () =>
  action(IcmsTypes.GET_FILE_TYPES_FAILURE);

export const setFileTypes = (fileTypes: Icms[]) =>
  action(IcmsTypes.SET_FILE_TYPES, { fileTypes });

export const getOrigins = () => action(IcmsTypes.GET_ORIGINS);

export const getOriginsFailure = () => action(IcmsTypes.GET_ORIGINS_FAILURE);

export const setOrigins = (origins: Icms[]) =>
  action(IcmsTypes.SET_ORIGINS, { origins });

export const marketsClean = () => action(IcmsTypes.MARKETS_CLEAN);
export const filetypesClean = () => action(IcmsTypes.FILE_TYPES_CLEAN);
export const originsClean = () => action(IcmsTypes.ORIGINS_CLEAN);
export const icmsClean = () => action(IcmsTypes.ICMS_CLEAN);
