import { action } from "typesafe-actions";
import { FilesTypes, Files, Body } from "./types";

// Files

export const getFiles = (body: Body) =>
  action(FilesTypes.GET_FILES, { ...body });

export const getFilesFailure = () => action(FilesTypes.GET_FILES_FAILURE);

export const setFiles = (data: Files) => action(FilesTypes.SET_FILES, { data });

export const filesClean = () => action(FilesTypes.FILES_CLEAN);
