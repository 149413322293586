import { action } from "typesafe-actions";
import { HistoryTypes, History, Body } from "./types";

// History

export const getHistory = (body: Body) =>
  action(HistoryTypes.GET_HISTORY, { ...body });

export const getHistoryFailure = () => action(HistoryTypes.GET_HISTORY_FAILURE);

export const setHistory = (data: History) =>
  action(HistoryTypes.SET_HISTORY, { data });

export const historyClean = () => action(HistoryTypes.HISTORY_CLEAN);
