// @ts-nocheck
import { put } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setRejections } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";

export function* getRejections() {
  try {
    const purchaseCancel = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS_OPTIONS_PURCHASE}/${Constants.CANCEL_OPTIONS}`
    );
    const review = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS_OPTIONS_SALE}/${Constants.REVIEW_REJECTION_OPTIONS}`
    );
    const sale = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS_OPTIONS_SALE}/${Constants.SALE_REJECTION_OPTIONS}`
    );
    const saleCancel = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS_OPTIONS_SALE}/${Constants.CANCEL_OPTIONS}`
    );
    const seller = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS_OPTIONS_SALE}/${Constants.SELLER_REJECTION_OPTIONS}`
    );

    yield put(
      setRejections({
        purchaseCancel: purchaseCancel.data,
        review: review.data,
        sale: sale.data,
        saleCancel: saleCancel.data,
        seller: seller.data,
      })
    );
  } catch (err) {}
}
