import { Reducer } from "redux";
import { IcmsState, IcmsTypes } from "./types";

const INITIAL_STATE: IcmsState = {
  markets: [],
  fileTypes: [],
  origins: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IcmsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IcmsTypes.GET_MARKETS:
      return { ...state, loading: true };
    case IcmsTypes.SET_MARKETS:
      return {
        ...state,
        loading: false,
        error: false,
        markets: action.payload.markets,
      };
    case IcmsTypes.GET_MARKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        markets: INITIAL_STATE.markets,
      };
    case IcmsTypes.MARKETS_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE.markets,
      };

    case IcmsTypes.GET_FILE_TYPES:
      return { ...state, loading: true };
    case IcmsTypes.SET_FILE_TYPES:
      return {
        ...state,
        loading: false,
        error: false,
        fileTypes: action.payload.fileTypes,
      };
    case IcmsTypes.GET_FILE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        fileTypes: INITIAL_STATE.fileTypes,
      };
    case IcmsTypes.FILE_TYPES_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE.fileTypes,
      };

    case IcmsTypes.GET_ORIGINS:
      return { ...state, loading: true };
    case IcmsTypes.SET_ORIGINS:
      return {
        ...state,
        loading: false,
        error: false,
        origins: action.payload.origins,
      };
    case IcmsTypes.GET_ORIGINS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        origins: INITIAL_STATE.origins,
      };
    case IcmsTypes.ORIGINS_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE.origins,
      };

    case IcmsTypes.ICMS_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case IcmsTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
