import axios from "axios";
import { ApiRoutes } from "../../config/api/routes";
import { headers } from "../../config/api";

export const axiosInstance = (baseURL: any, headers: any) =>
  axios.create({
    baseURL,
    headers,
    withCredentials: true
  });

//@ts-ignore
export const api = axiosInstance(
  process.env.REACT_APP_BASE_URL || ApiRoutes.PROD,
  headers.json
);
export const apiMultiPart = axiosInstance(
  process.env.REACT_APP_BASE_URL || ApiRoutes.PROD,
  headers.multipart
);
export const localApi = axiosInstance(ApiRoutes.LOCAL_API, headers.json);
export const localApiMultiPart = axiosInstance(
  ApiRoutes.LOCAL_API,
  headers.multipart
);
export const mockApi = axiosInstance(ApiRoutes.MOCK_API, headers.json);
export const mockApiMultiPart = axiosInstance(
  ApiRoutes.MOCK_API,
  headers.multipart
);
