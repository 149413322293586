import { Reducer } from "redux";
import { SignUpState, SignUpTypes } from "./types";
import Images from "../../../config/images";

const INITIAL_STATE: SignUpState = {
  data: {
    status: false,
    image: Images.LOADING,
  },
  error: false,
  loading: false,
};

const reducer: Reducer<SignUpState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SignUpTypes.SIGN_UP_REQUEST:
      return { ...state, loading: true };
    case SignUpTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case SignUpTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: { image: Images.FINISHED_WRONG, status: false },
      };
    case SignUpTypes.SIGN_UP_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case SignUpTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
