export enum ApiRoutes {
  ADMIN_COMPANY = "/admin/company",
  ADMIN_ICMS = "/admin/icms",
  ADMIN_ROLES = "/admin/roles",
  ADMIN_USERS = "/admin/users",
  BASE_URL = "someRouteHere",
  ICMS = "/icms",
  ICMS_OPTIONS_SALE = "/icms/options/sale",
  ICMS_SALE = "/icms/sale",
  ICMS_OPTIONS_PURCHASE = "/icms/options/purchase",
  ICMS_PURCHASE = "/icms/purchase",
  ICMS_ORDER = "/icms/order",
  LOCAL_API = "http://localhost:3000/dev",
  LOGIN = "/login",
  LOGOUT = "/logout",
  MOCK_API = "https://5e7e2796fa19eb001651a6ea.mockapi.io",
  NOTIFICATIONS = "/notifications",
  PROD = "http://localhost:3000",  
  REJECT_REASONS = "/reject-reasons",
  REGIONS = "/regions",
  STATES = "/states",
  SIGN_UP = "/sign-up",
  TEST = "/test",
  TOGGLE_USER_APPROVAL = "/toogle-user-approval",
  PRIVACY_NOTES = "/privacy-notes"
}
