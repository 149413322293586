import { action } from "typesafe-actions";
import { UserTypes, Body, Response, Location } from "./types";

export const getUserPurchases = (body: Body) =>
  action(UserTypes.GET_USER_PURCHASES, { ...body });

export const getUserPurchasesFailure = () =>
  action(UserTypes.GET_USER_PURCHASES_FAILURE);

export const setUserPurchases = (purchases: Response) =>
  action(UserTypes.SET_USER_PURCHASES, { purchases });

export const getUserSales = (body: Body) =>
  action(UserTypes.GET_USER_SALES, { ...body });

export const getUserSalesFailure = () =>
  action(UserTypes.GET_USER_SALES_FAILURE);

export const setUserSales = (sales: Response) =>
  action(UserTypes.SET_USER_SALES, { sales });

export const getLocation = (body: Location) =>
  action(UserTypes.GET_LOCATION, { ...body });

export const setLocation = (location: Location) =>
  action(UserTypes.SET_LOCATION, { location });

export const salesClean = () => action(UserTypes.SALES_CLEAN);
export const locationClean = () => action(UserTypes.LOCATION_CLEAN);
export const userClean = () => action(UserTypes.USER_CLEAN);
