/* eslint-disable import/first */
 import React from "react";

import { SalePurchaseDetails, TitleBackButton } from "../../../components";

import rolesConfig from "../../../config/roles";
import { typedUseSelector } from "../../../store";

const PurchaseDetails: React.FC = () => {
  const role = typedUseSelector((state) => state.auth.data.roles)[0];
  return (
    <React.Suspense fallback="">
      <TitleBackButton
        title="Status da compra de crédito de ICMS"
        route={rolesConfig.purchase[role]?.route}
      />
      <SalePurchaseDetails />
    </React.Suspense>
  );
};

export default React.memo(PurchaseDetails);
