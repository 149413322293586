import { Reducer } from "redux";
import { PretentionState, PretentionTypes } from "./types";

import { Constants } from "../../../config/constants";
import { Main } from "../../../styles/themes/Main";
const { colors } = Main;

const INITIAL_STATE: PretentionState = {
  data: {
    pretention: Constants.PURCHASE,
    color: {
      code: colors.primaryButtonAndLabels,
      name: Constants.PRIMARY,
    },
  },
};

const reducer: Reducer<PretentionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PretentionTypes.SET_PRETENTION:
      return {
        ...state,
        data: action.payload.data,
      };
    case PretentionTypes.RESET_PRETENTION:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case PretentionTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
