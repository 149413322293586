import { History, LocationState } from "history";

import { action } from "typesafe-actions";
import { AuthTypes, AuthBody, AuthResponse, PersistentAuth } from "./types";

// Auth

export const authRequest = (authBody: AuthBody) =>
  action(AuthTypes.AUTH_REQUEST, { ...authBody });

export const authSuccess = (data: AuthResponse) =>
  action(AuthTypes.AUTH_SUCCESS, { data });

export const persistentAuth = (persistentAuth: PersistentAuth) =>
  action(AuthTypes.PERSISTENT_AUTH, { ...persistentAuth });

export const authFailure = () => action(AuthTypes.AUTH_FAILURE);
export const authLogout = () => action(AuthTypes.AUTH_LOGOUT);
export const authClean = () => action(AuthTypes.AUTH_CLEAN);
