/* eslint-disable import/first */
 import React from "react";

import { TitleBackButton, SalePurchaseAccordion } from "../../../components";
import { typedUseSelector } from "../../../store";

import rolesConfig from "../../../config/roles";

const Purchases: React.FC = () => {
  const role = typedUseSelector((state) => state.auth.data.roles)[0];
  return (
    <React.Suspense fallback="">
      <TitleBackButton title={rolesConfig.purchase[role]?.title} />
      <SalePurchaseAccordion />
    </React.Suspense>
  );
};

export default React.memo(Purchases);
