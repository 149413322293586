import { History, LocationState } from "history";

export enum UserTypes {
  GET_USER_PURCHASES = "@userGET_USER_PURCHASES",
  SET_USER_PURCHASES = "@user/SET_USER_PURCHASES",
  GET_USER_PURCHASES_FAILURE = "@userGET_USER_PURCHASES_FAILURE",
  GET_USER_SALES = "@userGET_USER_SALES",
  SET_USER_SALES = "@user/SET_USER_SALES",
  GET_USER_SALES_FAILURE = "@userGET_USER_SALES_FAILURE",
  GET_LOCATION = "@userGET_LOCATION",
  SET_LOCATION = "@user/SET_LOCATION",
  PURCHASES_CLEAN = "@user/PURCHASES_CLEAN",
  SALES_CLEAN = "@user/SALES_CLEAN",
  LOCATION_CLEAN = "@user/LOCATION_CLEAN",
  USER_CLEAN = "@user/USER_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface Body {
  page: string | number | undefined;
  size: string | number | undefined;
  pretention: string;
  status?: string | undefined;
}

export interface Response {
  hasNext: boolean | undefined;
  hasPrevious: boolean | undefined;
  pageCount: number | undefined;
  currentPage: number | undefined;
  rows: any | undefined;
  count: number | undefined;
  size: number;
  page: number;
}
export interface Location {
  region: string | number;
  state: string | number;
}

export interface UserState {
  readonly location: Location;
  readonly purchases: Response;
  readonly sales: Response;
  readonly error: boolean;
  readonly loading: boolean;
}
