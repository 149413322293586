/* eslint-disable import/first */
 import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

import { Card, Button, ICMSNavbar } from "../../components";
import { typedUseSelector } from "../../store";
import { Main } from "../../styles/themes/Main";

import * as S from "./styles";

const Login: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <React.Suspense fallback="">
      <Card>
        <div>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat eaque
          reiciendis beatae repellat autem delectus labore praesentium
          reprehenderit distinctio ad? Voluptas iusto amet unde repellat
          veritatis, eius atque et hic?
        </div>
      </Card>
    </React.Suspense>
  );
};

export default React.memo(Login);
