// @ts-nocheck
import { put, select } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setPrivacyNotes, setAcceptPrivacyNotes } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";
import { privacyNotesSelector } from "./../selectors";
import { RoutesMap } from "./../../../routes/map";

export function* getPrivacyNotes() {
  try {
    const response = yield secureCall(
      Constants.GET,
      ApiRoutes.PRIVACY_NOTES,
      undefined,
      Constants.JSON
    );

    yield put(setPrivacyNotes(response.data));
  } catch (err) {}
}

export function* getAcceptPrivacyNotes() {
  try {
    const privacyNotesState = yield select(privacyNotesSelector);
    yield secureCall(
      Constants.POST,
      ApiRoutes.PRIVACY_NOTES,
      {
        id: privacyNotesState.data.id,
        accepted: true
      },
      Constants.JSON
    );

    yield put(setAcceptPrivacyNotes(true));
  } catch (err) {}
}
