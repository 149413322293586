/* eslint-disable import/first */
 import React from "react";

import { SaleStepper, Label, TitleBackButton } from "../../../components";

import { Main } from "../../../styles/themes/Main";
const { colors } = Main;

import { typedUseSelector } from "../../../store";

import rolesConfig from "../../../config/roles";

const Sale: React.FC = () => {
  const role = typedUseSelector((state) => state.auth.data.roles)[0];
  return (
    <React.Suspense fallback="">
      <TitleBackButton
        title="Venda de créditos ICMS"
        src={rolesConfig.sale[role]?.route}
      />
      <Label
        label="Formulário para venda de crédito ICMS"
        fontSize="14px"
        fontWeight={700}
        color={colors.modalText}
        margin="0 0 10px 0"
      />
      <SaleStepper />
    </React.Suspense>
  );
};

export default React.memo(Sale);
