import { Reducer } from "redux";
import { UserState, UserTypes } from "./types";
import Images from "../../../config/images";

const INITIAL_STATE: UserState = {
  purchases: {
    hasNext: undefined,
    hasPrevious: undefined,
    pageCount: undefined,
    currentPage: undefined,
    rows: [],
    count: undefined,
    page: 0,
    size: 10,
  },
  sales: {
    hasNext: undefined,
    hasPrevious: undefined,
    pageCount: undefined,
    currentPage: undefined,
    rows: [],
    count: undefined,
    page: 0,
    size: 10,
  },
  location: {
    region: "",
    state: "",
  },
  loading: false,
  error: false,
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.GET_USER_PURCHASES:
      return { ...state, loading: true };
    case UserTypes.SET_USER_PURCHASES:
      return {
        ...state,
        loading: false,
        error: false,
        purchases: action.payload.purchases,
      };
    case UserTypes.GET_USER_PURCHASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        purchases: INITIAL_STATE.purchases,
      };

    case UserTypes.GET_USER_SALES:
      return { ...state, loading: true };
    case UserTypes.SET_USER_SALES:
      return {
        ...state,
        loading: false,
        error: false,
        sales: action.payload.sales,
      };
    case UserTypes.GET_USER_SALES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        sales: INITIAL_STATE.sales,
      };

    case UserTypes.SET_LOCATION:
      return {
        ...state,
        location: action.payload.location,
      };

    case UserTypes.PURCHASES_CLEAN:
      return {
        ...state,
        purchases: INITIAL_STATE.purchases,
      };
    case UserTypes.SALES_CLEAN:
      return {
        ...state,
        sales: INITIAL_STATE.sales,
      };
    case UserTypes.LOCATION_CLEAN:
      return {
        ...state,
        location: INITIAL_STATE.location,
      };
    case UserTypes.USER_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case UserTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
