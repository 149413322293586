import { Reducer } from "redux";
import { StatusState, StatusTypes } from "./types";

const INITIAL_STATE: StatusState = {
  change: false,
  purchase: [
    {
      label: "",
      value: "",
    },
  ],
  sale: [
    {
      label: "",
      value: "",
    },
  ],
  reason: "",
  files: [],
  loading: false,
  error: false,
};

const reducer: Reducer<StatusState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StatusTypes.GET_STATUS:
      return { ...state, loading: true };
    case StatusTypes.SET_PURCHASE_STATUS:
      return {
        ...state,
        loading: false,
        error: false,
        purchase: action.payload.purchase,
      };
    case StatusTypes.SET_SALE_STATUS:
      return {
        ...state,
        loading: false,
        error: false,
        sale: action.payload.sale,
      };

    case StatusTypes.SET_MODIFY_STATUS:
      return {
        ...state,
        change: true,
      };
    case StatusTypes.MODIFY_STATUS_CLEAN:
      return {
        ...state,
        change: INITIAL_STATE.change,
      };

    case StatusTypes.SET_STATUS_FILES:
      return {
        ...state,
        files: action.payload.files,
      };
    case StatusTypes.SET_STATUS_REASON:
      return {
        ...state,
        reason: action.payload.reason,
      };
    case StatusTypes.SET_STATUS_DISCOUNT_PERCENTAGE:
      return {
        ...state,
        discountPercentage: action.payload.discountPercentage,
      };
    case StatusTypes.STATUS_FILES_AND_REASON_CLEAN:
      return {
        ...state,
        files: INITIAL_STATE.files,
        reason: INITIAL_STATE.reason,
      };

    case StatusTypes.STATUS_CLEAN:
      return {
        ...state,
        purchase: INITIAL_STATE.purchase,
        sale: INITIAL_STATE.sale,
      };
    case StatusTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
