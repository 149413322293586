// @ts-nocheck
import { createStore, applyMiddleware, Store } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import createSagaMiddleware from "redux-saga";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { AdminState } from "./ducks/admin/types";
import { AuthState } from "./ducks/auth/types";
import { CreditsState } from "./ducks/credits/types";
import { DocumentsState } from "./ducks/documents/types";
import { FilesState } from "./ducks/files/types";
import { HistoryState } from "./ducks/history/types";
import { IcmsState } from "./ducks/icms/types";
import { LocationState } from "./ducks/location/types";
import { ModalState } from "./ducks/modal/types";
import { NotificationsState } from "./ducks/notifications/types";
import { PretentionState } from "./ducks/pretention/types";
import { PurchaseState } from "./ducks/purchase/types";
import { RejectionsState } from "./ducks/rejections/types";
import { RolesState } from "./ducks/roles/types";
import { SaleState } from "./ducks/sale/types";
import { SignUpState } from "./ducks/signUp/types";
import { StatusState } from "./ducks/status/types";
import { StepsState } from "./ducks/steps/types";
import { ToastState } from "./ducks/toast/types";
import { UserState } from "./ducks/user/types";
import { PrivacyNotesState } from "./ducks/privacyNotes/types";

import rootReducer from "./ducks/rootReducer";
import rootSaga from "./ducks/rootSaga";

export interface ApplicationState {
  admin: AdminState;
  auth: AuthState;
  credits: CreditsState;
  documents: DocumentsState;
  files: FilesState;
  history: HistoryState;
  icms: IcmsState;
  location: LocationState;
  modal: ModalState;
  notifications: NotificationsState;
  pretention: PretentionState;
  purchase: PurchaseState;
  rejections: RejectionsState;
  roles: RolesState;
  sale: SaleState;
  signUp: SignUpState;
  status: StatusState;
  steps: StepsState;
  toast: ToastState;
  user: UserState;
  privacyNotes: PrivacyNotesState;
}

const persistConfig = {
  blacklist: ["admin", "icms", "modal", "notifications", "signUp", "toast"],
  key: "icmsmarketplace",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const typedUseSelector: TypedUseSelectorHook<ApplicationState> = useSelector;

const middleware = composeWithDevTools(applyMiddleware(sagaMiddleware));

const store: Store<ApplicationState> = createStore(
  persistedReducer,
  middleware
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
