export default {
  fields: [
    "name",
    "occupation",
    "socialReason",
    "cnpj",
    "email",
    "company",
    "legalRepresentative",
    "legalRepresentativeName",  
    "phoneNumber",
  ],
  arraysAndFiles: [
    "socialContract",
    "attorneyLetter",
    "roles",
  ],
  messages: {
    200: "Cadastro realizado com sucesso!",
    400: "Verifique os dados e tente novamente!",
    422: "E-mail já cadastro!",
    500: "Erro ao realizar cadastro, verifique os dados e tente novamente!",
  },
};
