import { History, LocationState } from "history";

export enum SignUpTypes {
  SIGN_UP_REQUEST = "@signUp/SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS = "@signUp/SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE = "@signUp/SIGN_UP_FAILURE",
  SIGN_UP_CLEAN = "@signUp/SIGN_UP_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL"
}

export interface SignUpBody {
  body: {
    name: string;
    occupation: string;
    socialReason: string;
    cnpj: string;
    email: string;
    company: string;
    socialContract: string;
    attorneyLetter?: string;    
    legalRepresentative: boolean;
    legalRepresentativeName?: string;   
    history: History<LocationState>;
  }
  callback: Function
}

export interface SignUp {
  image: string;
  status: boolean;
}

export interface SignUpState {
  readonly data: SignUp;
  readonly loading: boolean;
  readonly error: boolean;
}
