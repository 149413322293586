export enum PretentionTypes {
  GET_PRETENTION = "@pretention/GET_PRETENTION",
  SET_PRETENTION = "@pretention/SET_PRETENTION",
  RESET_PRETENTION = "@pretention/RESET_PRETENTION",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface Pretention {
  pretention: string;
  color: {
    code: string;
    name: string;
  };
}

export interface PretentionState {
  readonly data: Pretention;
}
