// @ts-nocheck
import { call, put, all, select, take } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import {
  setSale,
  persistSaleFailure,
  setSales,
  setCurrentManipulatedSale,
} from "./actions";
import { setToast } from "../toast/actions";
import { Constants } from "../../../config/constants";
import { getBodyAsFormData } from "../../../services/api/formData";
import {
  icmsSelector,
  locationSelector,
  pretentionSelector,
} from "../selectors";

import dataMap from "./dataMap";
import { secureCall, failure } from "../interceptor";
import { RoutesMap } from "../../../routes/map";
import { getFiles, filesClean, setFiles } from "../files/actions";
import { getStates } from "../location/actions";
import { LocationTypes } from "../location/types";
import { getHistory } from "../history/actions";
import { getNotifications } from "../notifications/actions";
import { resetModal } from "../modal/actions";
import { rightResponseStatus } from "../../../config/api";

export function* persistSale(action: any) {
  try {
    const { payload } = action;
    const { history, saleId } = payload;
    const newPayload = {
      ...payload,
      declarations: btoa(payload.declarations)
    }

    const body = getBodyAsFormData(newPayload, dataMap);
    const response = yield secureCall(
      saleId ? Constants.PUT : Constants.POST,
      saleId ? `${ApiRoutes.ICMS_SALE}/${saleId}` : ApiRoutes.ICMS_SALE,
      body,
      Constants.MULTIPART,
      "Erro ao realizar/atualizar venda",
      persistSaleFailure
    );

    if (rightResponseStatus.includes(response.status)) {
      yield all([
        put(setSale()),
        put(
          setToast({
            message: "Venda realizada/atualizada com sucesso!",
            severity: Constants.SUCCESS,
          })
        ),
        put(filesClean()),
      ]);
      history.replace(RoutesMap.MY_SALES);
    }
  } catch (err) {
    yield call(failure, undefined, "Falha ao realizar/atualizar venda");
  }
}

export function* getSales(action: any) {
  const {
    payload: { page, size, status },
  } = action;

  const params = status ? { page, size, status } : { page, size };

  const response = yield secureCall(
    Constants.GET,
    `${ApiRoutes.ADMIN_ICMS}/${Constants.SALES}`,
    { params }
  );

  yield put(setSales(response.data));
}

export function* getCurrentManipulatedSale(action: any) {
  const {
    payload: { id, orderId },
  } = action;

  const {
    data: { pretention },
  } = yield select(pretentionSelector);

  const currentManipulatedSale = yield call(getCurrentManipulatedSaleData, id);
  let files = currentManipulatedSale.files;
  if (orderId) {
    const order = currentManipulatedSale.orders.find((order: any) => order.id === orderId)
    files = files.concat(order.purchase.files)
  }

  yield all([
    put(setCurrentManipulatedSale(currentManipulatedSale)),
    put(getFiles({ [orderId ? 'orderId' : 'saleId']: orderId ? orderId : currentManipulatedSale.id })),
    put(getHistory({ id: currentManipulatedSale.id, pretention })),
    put(getNotifications()),
    put(resetModal()),
  ]);
}

export function* getCurrentManipulatedSaleData(id: number) {
  const currenSaleData = yield secureCall(
    Constants.GET,
    `${ApiRoutes.ICMS_SALE}/${id}`
  );

  const sale = currenSaleData.data;

  const { markets, origins } = yield select(icmsSelector);
  const { regions } = yield select(locationSelector);

  const regionId = getValueByValue(sale.region, regions);

  yield put(getStates({ idAsValue: false, regionId }));
  yield take(LocationTypes.SET_STATES);
  const { states } = yield select(locationSelector);

  const stateCode = getValueByValue(sale.state, states);
  const marketId = getValueByValue(sale.market, markets);
  const originId = getValueByValue(sale.origin, origins);

  return {
    id: sale.id,
    regionId,
    stateCode,
    city: sale.city,
    region: sale.region,
    state: sale.state,
    market: sale.market,
    marketId,
    value: sale.value,
    discount: sale.discount,
    discountedValue: sale.discountedValue,
    installmentsAmount: sale.installmentsAmount,
    validity: sale.validity,
    origin: sale.origin,
    originId,
    status: sale.status,
    orders: sale.orders,
    files: sale.files || []
  };
}

const getValueByValue = (value: string, source: any) =>
  source.find(({ label }: { label: string }) => label === value)?.value;
