export enum IcmsTypes {
  GET_MARKETS = "@market/GET_MARKETS",
  SET_MARKETS = "@market/SET_MARKETS",
  GET_MARKETS_FAILURE = "@market/GET_MARKETS_FAILURE",
  MARKETS_CLEAN = "@market/MARKETS_CLEAN",
  GET_FILE_TYPES = "@market/GET_FILE_TYPES",
  SET_FILE_TYPES = "@market/SET_FILE_TYPES",
  GET_FILE_TYPES_FAILURE = "@market/GET_FILE_TYPES_FAILURE",
  FILE_TYPES_CLEAN = "@market/FILE_TYPES_CLEAN",
  GET_ORIGINS = "@market/GET_ORIGINS",
  SET_ORIGINS = "@market/SET_ORIGINS",
  GET_ORIGINS_FAILURE = "@market/GET_ORIGINS_FAILURE",
  ORIGINS_CLEAN = "@market/ORIGINS_CLEAN",
  ICMS_CLEAN = "@icms/ICMS_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface Icms {
  label: string;
  value: string;
}

export interface IcmsState {
  readonly markets: Icms[];
  readonly fileTypes: Icms[];
  readonly origins: Icms[];
  readonly error: boolean;
  readonly loading: boolean;
}
