import { RoutesMap } from "../../routes/map";
import Images from "../images";
import { Constants } from "../constants";
import { Main } from "../../styles/themes/Main";
const { colors } = Main;

export default {
  purchase: {
    color: {
      code: colors.primaryButtonAndLabels,
      name: Constants.PRIMARY,
    },
    path: {
      details: RoutesMap.PURCHASE_DETAILS,
      list: RoutesMap.SHOW_CASE,
      public: RoutesMap.SHOW_CASE,
    },
    translation: {
      plural: "Compras",
      singular: "Compra",
    },
    image: Images.DETAILS_PURCHASE,
  },
  sale: {
    color: {
      code: colors.secondaryButtonAndLabels,
      name: Constants.SECONDARY,
    },
    path: {
      details: RoutesMap.SALE_DETAILS,
      list: RoutesMap.MY_SALES,
      public: RoutesMap.MY_SALES,
      order: RoutesMap.ORDER
    },
    translation: {
      plural: "Vendas",
      singular: "Venda",
    },
    image: Images.DETAILS_SALE,
  },
};
