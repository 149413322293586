export enum NotificationsTypes {
  GET_NOTIFICATIONS = "@notifications/GET_NOTIFICATIONS",
  SET_NOTIFICATIONS = "@notifications/SET_NOTIFICATIONS",
  GET_NOTIFICATIONS_FAILURE = "@notifications/GET_NOTIFICATIONS_FAILURE",
  NOTIFICATIONS_CLEAN = "@notifications/NOTIFICATIONS_CLEAN",
  CLEAN_ALL = "@app/CLEAN_all",
}

export interface Notification {
  sales: number;
  purchases: number;
  users: number;
}

export interface NotificationsState {
  readonly data: Notification;
  readonly error: boolean;
  readonly loading: boolean;
}
