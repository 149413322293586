export enum StatusTypes {
  GET_STATUS = "@status/GET_STATUS",
  SET_PURCHASE_STATUS = "@status/SET_PURCHASE_STATUS",
  SET_SALE_STATUS = "@status/SET_SALE_STATUS",

  GET_MODIFY_STATUS = "@status/PUT_MODIFY_STATUS",
  SET_MODIFY_STATUS = "@status/SET_MODIFY_STATUS",
  MODIFY_STATUS_CLEAN = "@status/MODIFY_STATUS_CLEAN",

  GET_MODIFY_ORDER_STATUS = "@status/PUT_MODIFY_ORDER_STATUS",

  SET_STATUS_FILES = "@status/SET_STATUS_FILES",
  SET_STATUS_REASON = "@status/SET_STATUS_REASON",
  SET_STATUS_DISCOUNT_PERCENTAGE = "@status/SET_STATUS_DISCOUNT_PERCENTAGE",
  STATUS_FILES_AND_REASON_CLEAN = "@status/STATUS_FILES_AND_REASON_CLEAN",

  STATUS_CLEAN = "@status/STATUS_CLEAN",
  CLEAN_ALL = "@app/CLEAN_all",
}

export interface Status {
  label: string;
  value: string;
}

export interface ModifyBody {
  cancel: boolean | undefined;
  id: string | number;
  pretention: string;
}

export interface StatusState {
  readonly purchase: Status[];
  readonly sale: Status[];
  readonly change: boolean;
  readonly reason: string;
  readonly files: any;
  readonly error: boolean;
  readonly loading: boolean;
}
