export default {
  fields: [
    "regionId",
    "stateCode",
    "city",
    "marketId",
    "value",
    "discount",
    "installmentsAmount",
    "validity",
    "originId",
    "declarations"
  ],
  arraysAndFiles: ["declarationFiles"],
};
