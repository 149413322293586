import styled from "styled-components";

interface AbsoluteContainerProps {
  left?: string;
  right?: string;
}

export const AbsoluteContainer = styled.div<AbsoluteContainerProps>`
  left: ${({ left }) => left};
  position: absolute;
  right: ${({ right }) => right};
  margin: auto 0;
`;

export const Container = styled.div`
  align-self: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 30vh;
`;

export const HiddenContainer = styled.div`
  background-color: transparent;
  height: 160px;
  width: 160px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSize.thirtyTwoPx};
  font-weight: bold;
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 8% auto;
  @media (min-height: 800px) {
    margin: 15% auto;
  }
`;
