export default {
  AVATAR: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/home/avatar.png`,
  DETAILS_PURCHASE: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/details/purchase.png`,
  DETAILS_SALE: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/details/sale.png`,
  FINISHED_RIGHT: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/home/finished-right.gif`,
  FINISHED_WRONG: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/home/finished-wrong.gif`,
  HOME_PURCHASE: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/home/purchase.png`,
  HOME_SALE: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/home/sale.png`,
  LOADING: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/home/loading.gif`,
  PENCIL: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/home/pencil.png`,
  LOGO: `${process.env.REACT_APP_REDIRECITAUTH || 'https://icms-exchange.ey.com'}/assets/images/home/icmsExchangewhite.png`
};
