import { Reducer } from "redux";
import { AdminState, AdminTypes } from "./types";

const INITIAL_STATE: AdminState = {
  data: {
    hasNext: undefined,
    hasPrevious: undefined,
    pageCount: undefined,
    currentPage: undefined,
    rows: [],
    count: undefined,
    page: 0,
    size: 10,
  },
  currentManipulatedUser: {
    id: 0,
    createdAt: "",
    companyId: 0,
    name: "",
    occupation: "",
    socialReason: "",
    cnpj: "",
    email: "",
    company: "",
    phoneNumber: "",
    legalRepresentativeName: "",
    deleted: false,
    verifiedUser: false,
    roles: [],
  },
  rejectReasons: [
    {
      label: "",
      value: "",
    },
  ],
  adminAndAdvisors: {
    hasNext: undefined,
    hasPrevious: undefined,
    pageCount: undefined,
    currentPage: undefined,
    rows: [],
    count: undefined,
    page: 0,
    size: 100000,
  },
  error: false,
  loading: false,
};

const reducer: Reducer<AdminState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminTypes.GET_USERS:
      return { ...state, loading: true };
    case AdminTypes.SET_USERS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case AdminTypes.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: INITIAL_STATE.data,
      };
    case AdminTypes.SET_CURRENT_MANIPULATED_USER:
      return {
        ...state,
        currentManipulatedUser: action.payload.currentManipulatedUser,
      };
    case AdminTypes.SET_REJECT_REASONS:
      return {
        ...state,
        rejectReasons: action.payload.rejectReasons,
      };
    case AdminTypes.CURRENT_MANIPULATED_USER_CLEAN:
      return {
        ...state,
        currentManipulatedUser: INITIAL_STATE.currentManipulatedUser,
      };
    case AdminTypes.ADMIN_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case AdminTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
      case AdminTypes.SET_ADMIN_AND_ADVISORS:
        return {
          ...state,
          adminAndAdvisors: action.payload.data,
        };
    default:
      return state;
  }
};

export default reducer;
