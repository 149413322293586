// @ts-nocheck
import { put } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import { setFiles } from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";
import { rightResponseStatus } from "../../../config/api";

export function* getFiles(action: any) {
  try {
    const {
      payload: { companyId, purchaseId, saleId, orderId },
    } = action;

    let route = `${ApiRoutes.ADMIN_COMPANY}/${companyId}/${Constants.FILES}`;

    if (purchaseId) {
      route = `${ApiRoutes.ICMS_PURCHASE}/${purchaseId}/${Constants.FILES}`;
    } else if (saleId) {
      route = `${ApiRoutes.ICMS_SALE}/${saleId}/${Constants.FILES}`;
    } else if (orderId) {
      route = `${ApiRoutes.ICMS_ORDER}/${orderId}/${Constants.FILES}`;
    }

    const response = yield secureCall(Constants.GET, route);

    if (rightResponseStatus.includes(response.status)) {
      yield put(setFiles({ files: response.data }));
    }
  } catch (err) {}
}
