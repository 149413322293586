// @ts-nocheck
import { put, all, select } from "redux-saga/effects";

import { ApiRoutes } from "../../../config/api/routes";
import {
  setPurchaseStatus,
  setSaleStatus,
  statusFilesAndReasonClean,
  setModifyStatus,
} from "./actions";
import { Constants } from "../../../config/constants";
import { secureCall } from "../interceptor";
import { statusSelector } from "../selectors";
import { getBodyAsFormData } from "../../../services/api/formData";
import { setToast } from "../toast/actions";
import { resetModal } from "../modal/actions";

export function* getStatus() {
  try {
    const purchase = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS_OPTIONS_PURCHASE}/${Constants.STATUS}`
    );

    const sale = yield secureCall(
      Constants.GET,
      `${ApiRoutes.ICMS_OPTIONS_SALE}/${Constants.STATUS}`
    );

    yield all([
      put(setPurchaseStatus(purchase.data)),
      put(setSaleStatus(sale.data)),
    ]);
  } catch (err) {}
}

export function* getModifyStatus(action: any) {
  try {
    let { payload } = action;
    const { cancel, id, pretention } = payload;
    const { files, reason } = yield select(statusSelector);

    const dataMap: any = { fields: [], arraysAndFiles: [] };

    if (files) {
      payload = { ...payload, files };
      dataMap.arraysAndFiles.push(Constants.FILES);
    }

    if (reason) {
      payload = { ...payload, reason };
      dataMap.fields.push(Constants.REASON);
    }

    if (cancel) {
      let cancelType =
        pretention === Constants.PURCHASE ? "cancelPurchase" : "cancelSale";
      payload = { ...payload, [cancelType]: cancel };
      dataMap.fields.push(cancelType);
    }

    const body = getBodyAsFormData(payload, dataMap);

    yield secureCall(
      Constants.PUT,
      `${ApiRoutes.ICMS}/sale/${id}/${Constants.STATUS}`,
      body,
      Constants.MULTIPART
    );

    const map = {
      purchase: {
        idType: "purchaseId",
      },
      sale: {
        idType: "saleId",
      },
    };

    yield all([
      put(
        setToast({
          message: "Status modificado com sucesso!",
          severity: Constants.SUCCESS,
        })
      ),
      put(resetModal()),
      put(setModifyStatus()),
      put(statusFilesAndReasonClean()),
    ]);
  } catch (err) {}
}

export function* getModifyOrderStatus(action: any) {
  try {
    let { payload } = action;
    const { cancel, id, pretention } = payload;
    const { files, reason, discountPercentage } = yield select(statusSelector);

    const dataMap: any = { fields: [], arraysAndFiles: [] };
    
    if (files) {
      payload = { ...payload, files };
      dataMap.arraysAndFiles.push(Constants.FILES);
    }
    if (discountPercentage) {
      payload = { ...payload, discountPercentage };
      dataMap.fields.push(Constants.DISCOUNT_PERCENTAGE);
    }
    if (reason) {
      payload = { ...payload, reason };
      dataMap.fields.push(Constants.REASON);
    }
    if (cancel) {
      let cancelType =
        pretention === Constants.PURCHASE ? "cancelPurchase" : "cancelSale";
      payload = { ...payload, [cancelType]: cancel };
      dataMap.fields.push(cancelType);
    }

    const body = getBodyAsFormData(payload, dataMap);

    const response = yield secureCall(
      Constants.PUT,
      `${ApiRoutes.ICMS}/order/${id}/${Constants.STATUS}`,
      body,
      Constants.MULTIPART
    );

    if (response) {
      yield all([
        put(
          setToast({
            message: "Status modificado com sucesso!",
            severity: Constants.SUCCESS,
          })
        ),
        put(resetModal()),
        put(setModifyStatus()),
        put(statusFilesAndReasonClean()),
      ]);
    } else {
      yield all([
        put(
          setToast({
            message: "Verifique as informações necessárias e tente novamente",
            severity: Constants.ERROR,
          })
        ),
        put(statusFilesAndReasonClean()),
      ]);
    }
  } catch (err) {}
}
