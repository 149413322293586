import { Reducer } from "redux";
import { StepsState, StepsTypes } from "./types";

const INITIAL_STATE: StepsState = {
  data: {
    purchase: [
      "Compra solicitada",
      "Contra proposta do comprador",
      "Contra proposta do vendedor",
      "Compra confirmada pelo vendedor",
      "Transação revisada pela EY",
      "Assinatura do contrato",
      "Nota fiscal emitida pelo vendedor",
      "Pagamento confirmado pelo vendedor",
      "Transferência de créditos confirmada pelo emissor",
    ],
    sale: [
      "Venda solicitada",
      "Análise da venda aprovada pela EY",
      "Venda publicada",
      "Compra solicitada",
      "Contra proposta do comprador",
      "Contra proposta do vendedor",
      "Compra revisada e aprovada pela EY",
      "Assinatura do contrato",
      "Nota fiscal emitida",
      "Pagamento confirmado"
    ],
  },
  currentSteps: {
    purchase: 0,
    sale: 0,
  },
  loading: false,
  error: false,
};

const reducer: Reducer<StepsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StepsTypes.GET_STEPS:
      return { ...state, loading: true };
    case StepsTypes.SET_STEPS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case StepsTypes.GET_STEPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: INITIAL_STATE.data,
      };

    case StepsTypes.SET_CURRENT_STEPS:
      return {
        ...state,
        currentSteps: action.payload.currentSteps,
      };
    case StepsTypes.CURRENT_STEPS_CLEAN:
      return {
        ...state,
        currentSteps: INITIAL_STATE.currentSteps,
      };
    case StepsTypes.STEPS_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case StepsTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
