import { action } from "typesafe-actions";
import {
  CreditsTypes,
  RegionsCredits,
  Credit,
  GetCreditsBody,
  GetCreditsResponse,
} from "./types";

// Credits

export const getCredits = (body: GetCreditsBody) =>
  action(CreditsTypes.GET_CREDITS, { ...body });

export const setCredits = (credits: GetCreditsResponse) =>
  action(CreditsTypes.SET_CREDITS, { credits });

export const getCreditsFailure = () => action(CreditsTypes.GET_CREDITS_FAILURE);

export const getCurrentCredit = (id: number) =>
  action(CreditsTypes.GET_CURRENT_CREDIT, { id });

export const setCurrentCredit = (current: Credit) =>
  action(CreditsTypes.SET_CURRENT_CREDIT, { current });

export const getRegionsCredits = () => action(CreditsTypes.GET_REGIONS_CREDITS);

export const getRegionsCreditsFailure = () =>
  action(CreditsTypes.GET_REGIONS_CREDITS_FAILURE);

export const setRegionsCredits = (regionsCredits: RegionsCredits) =>
  action(CreditsTypes.SET_REGIONS_CREDITS, { regionsCredits });

export const setShowCaseType = (showCaseType: string) =>
  action(CreditsTypes.SET_SHOW_CASE_TYPE, { showCaseType });

export const currentCreditClean = () =>
  action(CreditsTypes.CURRENT_CREDIT_CLEAN);
export const regionsCreditsClean = () =>
  action(CreditsTypes.REGIONS_CREDITS_CLEAN);
export const creditsClean = () => action(CreditsTypes.CREDITS_CLEAN);
