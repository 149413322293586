// @ts-nocheck
import { all, takeLatest } from "redux-saga/effects";

import { AdminTypes } from "./admin/types";
import { AuthTypes } from "./auth/types";
import { CreditsTypes } from "./credits/types";
import { FilesTypes } from "./files/types";
import { HistoryTypes } from "./history/types";
import { IcmsTypes } from "./icms/types";
import { LocationTypes } from "./location/types";
import { NotificationsTypes } from "./notifications/types";
import { PretentionTypes } from "./pretention/types";
import { PurchaseTypes } from "./purchase/types";
import { RejectionsTypes } from "./rejections/types";
import { RolesTypes } from "./roles/types";
import { SaleTypes } from "./sale/types";
import { SignUpTypes } from "./signUp/types";
import { StatusTypes } from "./status/types";
import { UserTypes } from "./user/types";
import { PrivacyNotesTypes } from "./privacyNotes/types";

import {
  getUsers,
  deleteUser,
  setUserApproval,
  persistUser,
  getCurrentManipulatedUser,
  getRejectReasons,
  getAdminAndAdvisors,
} from "./admin/sagas";
import { auth, logout, persistentAuth } from "./auth/sagas";
import {
  getRegionsCredits,
  getCurrentCredit,
  getCredits,
} from "./credits/sagas";
import { getFiles } from "./files/sagas";
import { getHistory } from "./history/sagas";
import { getMarkets, getFileTypes, getOrigins } from "./icms/sagas";
import { getStates, getRegions, getCities } from "./location/sagas";
import { getNotifications } from "./notifications/sagas";
import { getPretention } from "./pretention/sagas";
import {
  createPurchase,
  getPurchases,
  getCurrentManipulatedPurchase,
} from "./purchase/sagas";
import { getRejections } from "./rejections/sagas";
import { getRoles } from "./roles/sagas";
import { persistSale, getSales, getCurrentManipulatedSale } from "./sale/sagas";
import { signUp } from "./signUp/sagas";
import { getStatus, getModifyStatus, getModifyOrderStatus } from "./status/sagas";
import { getUserSalesOrPurchases } from "./user/sagas";
import { getPrivacyNotes, getAcceptPrivacyNotes } from "./privacyNotes/sagas";

export default function* rootSaga() {
  return yield all([
    takeLatest(AdminTypes.GET_REJECT_REASONS, getRejectReasons),
    takeLatest(
      AdminTypes.GET_CURRENT_MANIPULATED_USER,
      getCurrentManipulatedUser
    ),
    takeLatest(AdminTypes.PERSIST_USER, persistUser),
    takeLatest(AdminTypes.DELETE_USER, deleteUser),
    takeLatest(AdminTypes.SET_USER_APPROVAL, setUserApproval),
    takeLatest(AdminTypes.GET_USERS, getUsers),
    takeLatest(AdminTypes.GET_ADMIN_AND_ADVISORS, getAdminAndAdvisors),

    takeLatest(AuthTypes.AUTH_REQUEST, auth),
    takeLatest(AuthTypes.AUTH_LOGOUT, logout),
    takeLatest(AuthTypes.PERSISTENT_AUTH, persistentAuth),

    takeLatest(CreditsTypes.GET_CREDITS, getCredits),
    takeLatest(CreditsTypes.GET_CURRENT_CREDIT, getCurrentCredit),
    takeLatest(CreditsTypes.GET_REGIONS_CREDITS, getRegionsCredits),

    takeLatest(FilesTypes.GET_FILES, getFiles),

    takeLatest(HistoryTypes.GET_HISTORY, getHistory),

    takeLatest(IcmsTypes.GET_MARKETS, getMarkets),
    takeLatest(IcmsTypes.GET_FILE_TYPES, getFileTypes),
    takeLatest(IcmsTypes.GET_ORIGINS, getOrigins),

    takeLatest(LocationTypes.GET_REGIONS, getRegions),
    takeLatest(LocationTypes.GET_STATES, getStates),
    takeLatest(LocationTypes.GET_CITIES, getCities),

    takeLatest(NotificationsTypes.GET_NOTIFICATIONS, getNotifications),

    
    takeLatest(PretentionTypes.GET_PRETENTION, getPretention),

    takeLatest(PurchaseTypes.CREATE_PURCHASE, createPurchase),
    takeLatest(PurchaseTypes.GET_PURCHASES, getPurchases),
    takeLatest(
      PurchaseTypes.GET_CURRENT_MANIPULATED_PURCHASE,
      getCurrentManipulatedPurchase
    ),

    takeLatest(RejectionsTypes.GET_REJECTIONS, getRejections),
    takeLatest(RolesTypes.GET_ROLES, getRoles),

    takeLatest(SaleTypes.PERSIST_SALE, persistSale),
    takeLatest(SaleTypes.GET_SALES, getSales),
    takeLatest(
      SaleTypes.GET_CURRENT_MANIPULATED_SALE,
      getCurrentManipulatedSale
    ),

    takeLatest(SignUpTypes.SIGN_UP_REQUEST, signUp),

    takeLatest(StatusTypes.GET_STATUS, getStatus),
    takeLatest(StatusTypes.GET_MODIFY_STATUS, getModifyStatus),
    takeLatest(StatusTypes.GET_MODIFY_ORDER_STATUS, getModifyOrderStatus),

    takeLatest(UserTypes.GET_USER_PURCHASES, getUserSalesOrPurchases),
    takeLatest(UserTypes.GET_USER_SALES, getUserSalesOrPurchases),

    takeLatest(PrivacyNotesTypes.GET_PRIVACY_NOTES, getPrivacyNotes),
    takeLatest(PrivacyNotesTypes.GET_ACCEPT_PRIVACY_NOTES, getAcceptPrivacyNotes),
  ]);
}
