import { ReactLazyPreload } from "../config/lazyload";

const AdminConsultantAccordion = ReactLazyPreload(() =>
  import("./admin-consultant/AdminConsultantAccordion")
);

const Accordion = ReactLazyPreload(() => import("./general/Accordion"));
const DropFiles = ReactLazyPreload(() => import("./general/DropFiles"));
const DropFilesList = ReactLazyPreload(() => import("./general/DropFilesList"));
const FileDownload = ReactLazyPreload(() =>
  import("./general/FileDownloadList/FileDownload")
);
const FileDownloadList = ReactLazyPreload(() =>
  import("./general/FileDownloadList")
);
const HorizontalStepper = ReactLazyPreload(() =>
  import("./general/HorizontalStepper")
);
const ICMSImageUploader = ReactLazyPreload(() =>
  import("./general/ImageUploader")
);
const ICMSLogo = ReactLazyPreload(() => import("./general/Logo"));
const ICMSNavbar = ReactLazyPreload(() => import("./general/Navbar"));
const ICMSWrapper = ReactLazyPreload(() => import("./general/Wrapper"));
const Modal = ReactLazyPreload(() => import("./general/Modal"));
const Notifications = ReactLazyPreload(() => import("./general/Notifications"));
const Pagination = ReactLazyPreload(() => import("./general/Pagination"));
const ProfilePictureAvatar = ReactLazyPreload(() =>
  import("./general/ProfilePictureAvatar")
);
const SelectFilter = ReactLazyPreload(() => import("./general/SelectFilter"));
const TabFilters = ReactLazyPreload(() => import("./general/TabFilters"));
const TextFieldFilter = ReactLazyPreload(() =>
  import("./general/TextFieldFilter")
);
const ValueUnitLabel = ReactLazyPreload(() =>
  import("./general/ValueUnitLabel")
);
const VerticalStepper = ReactLazyPreload(() =>
  import("./general/VerticalStepper")
);

const Avatar = ReactLazyPreload(() => import("./global/Avatar"));
const Button = ReactLazyPreload(() => import("./global/Button"));
const Card = ReactLazyPreload(() => import("./global/Card"));
const ComposedLabel = ReactLazyPreload(() => import("./global/ComposedLabel"));
const FAB = ReactLazyPreload(() => import("./global/FAB"));
const FABMenu = ReactLazyPreload(() => import("./global/FABMenu"));
const GenericContainer = ReactLazyPreload(() =>
  import("./global/GenericContainer")
);
const Image = ReactLazyPreload(() => import("./global/Image"));
const Loading = ReactLazyPreload(() => import("./global/Loading"));
const Label = ReactLazyPreload(() => import("./global/Label"));
const Toast = ReactLazyPreload(() => import("./global/Toast"));
const TitleBackButton = ReactLazyPreload(() =>
  import("./global/TitleBackButton")
);
const ICMSRadio = ReactLazyPreload(() => import("./global/Radio"));
const ICMSSelect = ReactLazyPreload(() => import("./global/Select"));
const ICMSSlider = ReactLazyPreload(() => import("./global/Slider"));
const ICMSTextField = ReactLazyPreload(() => import("./global/TextField"));

const CardButtonHome = ReactLazyPreload(() => import("./home/CardButtonHome"));

const SalePurchaseAccordion = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseAccordion")
);
const SalePurchaseCard = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseCard")
);
const SalePurchaseData = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseData")
);
const SalePurchaseDetails = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseDetails")
);
const SalePurchaseFilter = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseFilter")
);
const SalePurchaseStatusDocumentsCard = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseStatusDocumentsCard")
);
const SalePurchaseStatusHistoryCard = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseStatusHistoryCard")
);
const SalePurchaseStatusHistoryItem = ReactLazyPreload(() =>
  import(
    "./sale-purchase/SalePurchaseStatusHistoryCard/SalePurchaseStatusHistoryItem"
  )
);
const SaleOrderStatusDetailsCard = ReactLazyPreload(() =>
  import("./sale-purchase/SaleOrderStatusDetailsCard")
);
const SalePurchaseStatusDetailsCard = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseStatusDetailsCard")
);
const SalePurchaseShowCase = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseShowCase")
);
const SalePurchaseStatusStepper = ReactLazyPreload(() =>
  import("./sale-purchase/SalePurchaseStatusStepper")
);
const SaleStepper = ReactLazyPreload(() =>
  import("./sale-purchase/SaleStepper")
);

const OrderDetails = ReactLazyPreload(() =>
  import("./order/OrderDetails")
);

const OrderStatusStepper = ReactLazyPreload(() =>
  import("./order/OrderStatusStepper")
);

const OrderInfosCard = ReactLazyPreload(() => 
  import("./order/OrderInfosCard")
);

const OrderHistoryCard = ReactLazyPreload(() => 
  import("./order/OrderHistoryCard")
)

const OrderHistoryItem = ReactLazyPreload(() => 
  import("./order/OrderHistoryCard/OrderHistoryItem")
)

const OrderPurchaseDocumentsCard = ReactLazyPreload(() => 
  import("./order/OrderPurchaseDocumentsCard")
)

const PrivacyNotesComponent = ReactLazyPreload(() => 
  import("./privacy-notes")
)

export {
  Accordion,
  AdminConsultantAccordion,
  Avatar,
  Button,
  Card,
  CardButtonHome,
  ComposedLabel,
  DropFiles,
  DropFilesList,
  FileDownload,
  FileDownloadList,
  FAB,
  FABMenu,
  GenericContainer,
  HorizontalStepper,
  Image,
  ICMSImageUploader,
  ICMSLogo,
  ICMSRadio,
  ICMSSelect,
  ICMSSlider,
  ICMSTextField,
  ICMSNavbar,
  ICMSWrapper,
  Label,
  Loading,
  Modal,
  Notifications,
  Pagination,
  ProfilePictureAvatar,
  SalePurchaseAccordion,
  SalePurchaseCard,
  SalePurchaseData,
  SalePurchaseDetails,
  SalePurchaseFilter,
  SalePurchaseStatusDocumentsCard,
  SalePurchaseStatusHistoryCard,
  SalePurchaseStatusHistoryItem,
  SalePurchaseShowCase,
  SalePurchaseStatusDetailsCard,
  SalePurchaseStatusStepper,
  SaleStepper,
  SelectFilter,
  TextFieldFilter,
  TitleBackButton,
  Toast,
  TabFilters,
  VerticalStepper,
  ValueUnitLabel,
  SaleOrderStatusDetailsCard,
  OrderDetails,
  OrderStatusStepper,
  OrderInfosCard,
  OrderHistoryCard,
  OrderHistoryItem,
  OrderPurchaseDocumentsCard,
  PrivacyNotesComponent
};
