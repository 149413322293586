import { Reducer } from "redux";
import { ModalState, ModalTypes } from "./types";

import { Constants } from "../../../config/constants";

const INITIAL_STATE: ModalState = {
  data: {
    animation: true,
    body: Constants.LOGIN,
    footer: undefined,
    centered: true,
    scrollable: true,
    size: Constants.SM,
    title: "",
  },
  show: false,
};

const reducer: Reducer<ModalState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalTypes.SET_MODAL:
      return {
        ...state,
        show: true,
        data: action.payload.data,
      };
    case ModalTypes.RESET_MODAL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case ModalTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
