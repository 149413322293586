export enum Constants {
  ABSOLUTE = "absolute",
  ADMIN = "ADMIN",
  APPROVE = "approve",
  ATTORNEY_LETTER = "ATTORNEY_LETTER",
  AUTO = "auto",
  BACK_PT = "Voltar",
  BLOCK = "block",
  CAPTION = "caption",
  CANCEL = "cancel",
  CANCEL_OPTIONS = "cancel-options",
  CANCEL_PT = "Cancelar",
  CENTER = "center",
  CITIES = "cities",
  COLUMN = "column",
  CONSULTANT = "CONSULTANT",
  COUNTER_OFFER = "counterOffer",
  CONTRACT = "contract",
  CRUD_USER = "crudUser",
  CREDITS = "credits",
  DATA = "data",
  DATA_BASE_64 = "data:image/png;base64,",
  DATE_FORMAT = "DD/MM/YYYY",
  DEFAULT = "default",
  DETAILED = "detailed",
  DELETE = "delete",
  DISCOUNT_PERCENTAGE = "discountPercentage",
  ERROR = "error",
  FILES = "files",
  FIXED = "fixed",
  FLEX = "flex",
  FLEX_START = "flex-start",
  FILE_TYPES = "file-types",
  FIRST = "first",
  GET = "get",
  HISTORY = "history",
  HORIZONTAL = "horizontal",
  INVOICE = "invoice",
  INFO = "info",
  INVALID_CNPJ = "CNPJ Inválido",
  INVALID_EMAIL = "E-mail inválido!",
  INVALID_PHONE_NUMBER = "Número de telefone inválido!",
  INVALID_VALUE = "Valor inválido",
  JSON = "json",
  JWT = "jwt",
  LG = "lg",
  LOGIN = "login",
  MAIN = "main",
  MD = "md",
  MARKETS = "markets",
  MARKETPLACE = "exchange",
  MULTIPART = "multipart",
  NEXT_PT = "Próximo",
  NONE = "none",
  NOT_APPLICABLE = "N/A",
  POINTER = "pointer",
  PARTIAL = "partial",
  POST = "post",
  PRIVATE = "private",
  PUBLIC = "public",
  PURCHASE = "purchase",
  PURCHASES = "purchases",
  PUT = "put",
  PRIMARY = "primary",
  ORIGINS = "origins",
  RANGE = "range",
  RELATIVE = "relative",
  REASON = "reason",
  REGION_CREDITS = "regionCredits",
  REJECT = "reject",
  REJECTION = "rejection",
  REVIEW_REJECTION_OPTIONS = "review-rejection-options",
  ROLES = "roles",
  ROW = "row",
  SALE = "sale",
  SALE_REJECTION_OPTIONS = "sale-rejection-options",
  SALES = "sales",
  SECOND = "second",
  SECONDARY = "secondary",
  SELLER_REJECTION_OPTIONS = "seller-rejection-options",
  SIGN_UP = "signUp",
  SIGN_UP_PT = "Cadastrar",
  SPACE_AROUND = "space-around",
  SPACE_BETWEEN = "space-between",
  SHOW_CASE = "showcase",
  SM = "sm",
  SOCIAL_CONTRACT = "SOCIAL_CONTRACT",
  STATES = "states",
  STRING = "string",
  STATUS = "status",
  SUCCESS = "success",
  TOTAL = "total",
  THIRD = "third",
  USER = "USER",
  USERS = "users",
  USER_PURCHASES = "user-purchases",
  USER_SALES = "user-sales",
  VERTICAL = "vertical",
  TERTIARY = "tertiary",
  USER_VALIDATION = "userValidation",
  WARNING = "warning",
  WRAP = "wrap",
  XL = "xl",
}
