export enum StepsTypes {
  GET_STEPS = "@steps/GET_STEPS",
  SET_STEPS = "@steps/SET_STEPS",
  SET_CURRENT_STEPS = "@steps/SET_CURRENT_STEPS",
  GET_STEPS_FAILURE = "@steps/GET_STEPS_FAILURE",
  CURRENT_STEPS_CLEAN = "@steps/CURRENT_STEPS_CLEAN",
  STEPS_CLEAN = "@steps/STEPS_CLEAN",
  CLEAN_ALL = "@app/CLEAN_ALL",
}

export interface Steps {
  purchase: string[];
  sale: string[];
}

export interface CurrentSteps {
  purchase: number;
  sale: number;
}

export interface StepsState {
  readonly data: Steps;
  readonly currentSteps: CurrentSteps;
  readonly error: boolean;
  readonly loading: boolean;
}
