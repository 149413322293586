import { Reducer } from "redux";
import { SaleState, SaleTypes } from "./types";
import Images from "../../../config/images";

const INITIAL_STATE: SaleState = {
  data: {
    image: Images.LOADING,
    status: false,
  },
  sales: {
    hasNext: undefined,
    hasPrevious: undefined,
    pageCount: undefined,
    currentPage: undefined,
    rows: [],
    count: undefined,
    page: 0,
    size: 10,
  },
  currentManipulatedSale: {
    id: 0,
    value: "",
    discount: 20,
    discountedValue: "",
    validity: 0,
    installmentsAmount: 0,
    origin: "",
    originId: 0,
    market: "",
    marketId: 0,
    region: "",
    state: "",
    stateCode: "",
    city: "",
    status: "",
  },
  orderId: "",
  loading: false,
  error: false,
};

const reducer: Reducer<SaleState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SaleTypes.SET_SALE:
      return {
        ...state,
        data: { image: Images.FINISHED_RIGHT, status: true },
      };
    case SaleTypes.PERSIST_SALE_FAILURE:
      return {
        ...state,
        loading: false,
        data: { image: Images.FINISHED_WRONG, status: false },
      };

    case SaleTypes.GET_SALES:
      return { ...state, loading: true };
    case SaleTypes.SET_SALES:
      return {
        ...state,
        loading: false,
        error: false,
        sales: action.payload.sales,
      };
    case SaleTypes.GET_SALES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        sales: INITIAL_STATE.sales,
      };

    case SaleTypes.GET_CURRENT_MANIPULATED_SALE:
      return {
        ...state,
        loading: true,
      };
    case SaleTypes.SET_CURRENT_MANIPULATED_SALE:
      return {
        ...state,
        loading: false,
        error: false,
        currentManipulatedSale: action.payload.currentManipulatedSale,
      };
    case SaleTypes.GET_CURRENT_MANIPULATED_ORDER_ID:
      return {
        ...state,
        loading: true
      };
    case SaleTypes.SET_CURRENT_MANIPULATED_ORDER_ID:
      return {
        ...state,
        loading: false,
        error: false,
        orderId: action.payload,
      };
    case SaleTypes.SALE_CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case SaleTypes.CURRENT_MANIPULATED_SALE_CLEAN:
      return {
        ...state,
        loading: false,
        currentManipulatedSale: INITIAL_STATE.currentManipulatedSale,
      };
    case SaleTypes.SALES_CLEAN:
      return {
        ...state,
        sales: INITIAL_STATE.sales,
      };
    case SaleTypes.CLEAN_ALL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
