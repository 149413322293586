/* eslint-disable import/first */
 import React from "react";

import {
  CardButtonHome,
  Image,
  Label,
  GenericContainer,
} from "../../components";
import { Main } from "../../styles/themes/Main";

const { colors } = Main;

import * as S from "./styles";
import Images from "../../config/images";
import { typedUseSelector } from "../../store";
import { Constants } from "../../config/constants";
import { useInjection } from "../../ioc.react";
import { TYPES } from "../../services/TYPES";
import { getIp } from "../../utils/infra/getIp";
import { ILogService } from "../../services/infra/LogService";
const Home: React.FC = () => {  
  const logService = useInjection<ILogService>(TYPES.LogService);    
  const email = typedUseSelector((state) => state.auth.data.email);
  getIp().then(result => {    
    logService.registerAudit(email, result, 'User with access');
  });
  const pretention = typedUseSelector(
    (state) => state.pretention.data.pretention
  );

  const purchaseImage =
    pretention === Constants.PURCHASE ? (
      <S.AbsoluteContainer left="0">
        <Image height="160px" src={Images.HOME_PURCHASE} width="160px" />
      </S.AbsoluteContainer>
    ) : null;

  const saleImage =
    pretention === Constants.SALE ? (
      <S.AbsoluteContainer right="0">
        <Image height="160px" src={Images.HOME_SALE} width="160px" />
      </S.AbsoluteContainer>
    ) : null;

  return (
    <React.Suspense fallback="">
      <S.Wrapper>
        {purchaseImage}
        <S.Container>
          <S.TitleContainer>
            <S.Title>Interveniência Técnica na compra e venda de ICMS</S.Title>
            <Label
              color={colors.black}
              label="Selecione sua opção para acessar o Exchange."
              fontSize="14px"
              fontWeight="400"
            />
          </S.TitleContainer>
          <CardButtonHome />
        </S.Container>
        {saleImage}
      </S.Wrapper>
    </React.Suspense>
  );
};

export default React.memo(Home);
