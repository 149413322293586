/* eslint-disable import/first */
 import React from "react";

import { AdminConsultantAccordion, TitleBackButton } from "../../components";

const Admin: React.FC = () => {
  return (
    <React.Suspense fallback="">
      <TitleBackButton title="Dashboard" />
      <AdminConsultantAccordion />
    </React.Suspense>
  );
};

export default React.memo(Admin);
