/* eslint-disable import/first */
 import React from "react";
import { useHistory } from "react-router-dom";

import { TitleBackButton, SalePurchaseShowCase } from "../../../components";

import rolesConfig from "../../../config/roles";
import { RoutesMap } from "../../../routes/map";
import { typedUseSelector } from "../../../store";

const ShowCase: React.FC = () => {
  const role = typedUseSelector((state) => state.auth.data.roles)[0];
  const privacyNotes = typedUseSelector((state) => state.privacyNotes);
  const history = useHistory();
  if (!privacyNotes?.data?.hasAccepted) {
    history.replace(RoutesMap.PRIVACY_NOTES);
  }
  return (
    <React.Suspense fallback="">
      <TitleBackButton
        title="Compra de créditos ICMS"
        route={rolesConfig.purchase[role]?.route}
      />
      <SalePurchaseShowCase />
    </React.Suspense>
  );
};

export default React.memo(ShowCase);
