// @ts-nocheck
import { call, put, select, all, take } from "redux-saga/effects";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { ApiRoutes } from "../../../config/api/routes";
import pretentionConfig from "../../../config/pretention";
import { authSuccess } from "./actions";
import { setToast } from "../toast/actions";
import { Constants } from "../../../config/constants";
import routes from "../../../routes/routes";
import { authSelector, pretentionSelector, privacyNotesSelector } from "../selectors";
import { resetModal } from "../modal/actions";
import { setShowCaseType } from "../credits/actions";
import { appCleanAll } from "../app/action";
import { AppTypes } from "../app/types";
import { RoutesMap } from "../../../routes/map";
import { getRegions } from "../location/actions";
import { getNotifications } from "../notifications/actions";
import { getMarkets, getOrigins } from "../icms/actions";
import { getPrivacyNotes } from "./../privacyNotes/actions";
import { getStatus } from "../status/actions";
import { secureCall, secureCallApi, failure } from "../interceptor";

export function* auth(action: any) {
  try {
    const {
      payload: { history, rememberMe, fromNavbar, userIp },
    } = action;    
    var args = {        
          headers: {
            "Authorization" : action.payload.idTokenActiveDirectory,
            "Content-Type" : "application/json",
            "User-Ip": userIp
      }
    }

    const response = yield secureCallApi(
      Constants.POST,
      ApiRoutes.LOGIN,
      args,      
      "Erro ao realizar login, verifique seus dados"
    );

    if (response.status === 200) {
      const {
        data: { pretention },
      } = yield select(pretentionSelector);
      const {
        data: { verifiedUser, roles, email, privacyNotes },
      } = response;

      if (verifiedUser) {
        let route = routes.find((route) => route.owner === roles[0])?.path;
        if (roles[0] === Constants.USER)
          route = pretentionConfig[pretention].path.list;

        yield all([
          put(authSuccess({ ...response.data, email })),
          call(setInitialConfiguraton),
          put(resetModal()),
          put(
            setToast({
              message: "Login efetuado com sucesso",
              severity: Constants.SUCCESS,
            })
          ),
        ]);
        if (!fromNavbar) {
          return history.replace(RoutesMap.PRIVACY_NOTES);
        }
      } else {
        const currentAccount = action.payload.instance.getAccountByUsername(email);
        const logoutParams = {
          account: currentAccount,
          onRedirectNavigate: () => false
        }
        action.payload.instance.logoutRedirect(logoutParams);
        yield put(
          setToast({
            message: "Erro ao realizar login, usuário desabilitado, aguarde a aprovação do administrador",
            severity: Constants.ERROR,
            error: true,
          })
        );
      }
    }
  } catch (err) {
    const currentAccount = action.payload.instance.getAccountByUsername(action.payload.instance.getAllAccounts()[0].username);
    const logoutParams = {
      account: currentAccount,
      onRedirectNavigate: () => false
    }
    action.payload.instance.logoutRedirect(logoutParams);
    yield call(
      failure,
      undefined,
      "Erro ao realizar login, verifique seus dados e tente novamente ou faça seu cadastro"
    );

    // abrir modal de signup
  }
}

export function* setInitialConfiguraton() {
  yield all([
    put(getPrivacyNotes()),
    put(getMarkets()),
    put(getNotifications()),
    put(getOrigins()),
    put(getRegions()),
    put(getStatus()),
    put(setShowCaseType(Constants.DETAILED)),
  ]);
}

export function* persistentAuth(action: any) {
  const {
    payload: { history },
  } = action;

  const {
    data: { rememberMe, roles },
    status,
  } = yield select(authSelector);

  const {
    data: { pretention },
  } = yield select(pretentionSelector);

  if (rememberMe && status) {
    let route = routes.find((route) => route.owner === roles[0])?.path;
    if (roles[0] === Constants.USER)
      route = pretentionConfig[pretention].path.list;
    history.replace(route);
  } else {
    yield put(appCleanAll());
  }
}

export function* logout() {
  yield secureCall(
    Constants.POST,
    ApiRoutes.LOGOUT
  );
  yield put(appCleanAll());
  setTimeout(() => {
    window.location.replace(RoutesMap.LOGOUT);
  }, 500);
  yield take(AppTypes.CLEAN_ALL);
}
