import { action } from "typesafe-actions";
import {
  SaleTypes,
  SaleBody,
  Body,
  Response,
  Sale,
  GetCurrentManipulatedSaleBody,
} from "./types";

// Sale

export const persistSaleFailure = () => action(SaleTypes.PERSIST_SALE_FAILURE);

export const setSale = () => action(SaleTypes.SET_SALE);

export const persistSale = (body: SaleBody) =>
  action(SaleTypes.PERSIST_SALE, { ...body });

export const getSales = (body: Body) =>
  action(SaleTypes.GET_SALES, { ...body });

export const getSalesFailure = () => action(SaleTypes.GET_SALES_FAILURE);

export const setSales = (sales: Response) =>
  action(SaleTypes.SET_SALES, { sales });

export const getCurrentManipulatedSale = (
  body: GetCurrentManipulatedSaleBody
) => action(SaleTypes.GET_CURRENT_MANIPULATED_SALE, { ...body });

export const setCurrentManipulatedSale = (currentManipulatedSale: Sale) => {
  return action(SaleTypes.SET_CURRENT_MANIPULATED_SALE, { currentManipulatedSale });
}

export const getCurrentManipulatedOrderId = () => action(SaleTypes.GET_CURRENT_MANIPULATED_ORDER_ID);

export const setCurrentManipulatedOrderId = (orderId: Number | String) => {
  return action(SaleTypes.SET_CURRENT_MANIPULATED_ORDER_ID, orderId);
}

export const currentManipulatedSaleClean = () =>
  action(SaleTypes.CURRENT_MANIPULATED_SALE_CLEAN);
export const saleClean = () => action(SaleTypes.SALE_CLEAN);
export const salesClean = () => action(SaleTypes.SALES_CLEAN);
