export const getBodyAsFormData = (payload: any, dataMap: any) => {
  let formData = new FormData();
  for (let i = 0; i < dataMap.fields.length; i += 1) {
    formData.set(dataMap.fields[i], payload[dataMap.fields[i]]);
  }
  formData = setArraysAndFiles(formData, payload, dataMap.arraysAndFiles);
  return formData;
};

export const setArraysAndFiles = (
  formData: FormData,
  payload: any,
  arraysAndFiles: any
) => {
  for (let i = 0; i < arraysAndFiles.length; i += 1) {
    if (Array.isArray(payload[arraysAndFiles[i]])) {
      for (let j = 0; j < payload[arraysAndFiles[i]].length; j += 1) {
        formData.append(arraysAndFiles[i], payload[arraysAndFiles[i]][j]);
      }
    } else {
      formData.append(arraysAndFiles[i], payload[arraysAndFiles[i]]);
    }
  }
  return formData;
};
