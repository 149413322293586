import { action } from "typesafe-actions";
import { RejectionsTypes, Rejections } from "./types";

// Rejections

export const getRejections = () => action(RejectionsTypes.GET_REJECTIONS);

export const getRejectionsFailure = () =>
  action(RejectionsTypes.GET_REJECTIONS_FAILURE);

export const setRejections = (rejections: Rejections) =>
  action(RejectionsTypes.SET_REJECTIONS, { ...rejections });

export const rejectionsClean = () => action(RejectionsTypes.REJECTIONS_CLEAN);
